import React, { useState } from 'react'
import {
    Button,
    CircularProgress,
    IconButton,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
} from '@mui/material'
import typoStyles from '../../../Styles/component/Typography.module.css'
import TableHeader from '../../../Components/TableHeader'
import { useTableService } from '../../../Services/taskManagerService/tableService'
import { useDispatch } from 'react-redux'
import TaskNoRecord from '../../../Components/TaskNoRecord/TaskNoRecord'
import TablePagination from '../../../Components/TablePagination/TablePagination'
import { useNavigate } from 'react-router-dom'
import { StatusTag } from '../../TaskManager/JobManagerMUIStyles'
import MessageIcon from '../../../Assests/Icons/MessageIcon.png'
import moment from 'moment'

type ManagerGraphTypes = {
    sortingObj?: any
    handleRequestSort?: any
    auditors?: any
    handleSetting?: any
    pagination?: any
    setPagination?: any
    selected?: any
    setSelected?: React.Dispatch<React.SetStateAction<number>>
}
function ManagerActivityGraph({
    sortingObj,
    handleRequestSort,
    auditors,
    handleSetting,
    pagination,
    setPagination,
    selected,
    setSelected,
}: ManagerGraphTypes) {
    const dispatch = useDispatch()
    const { selectField } = useTableService({
        from: 'UserActivity',
    })

    return auditors ? (
        <>
            <TableContainer sx={{ height: '75dvh' }}>
                <Table
                    stickyHeader
                    // sx={{ width: '10dvw', borderBlock: 'black' }}
                    aria-label="simple table"
                >
                    <TableHeader
                        sortingObj={sortingObj}
                        handleRequestSort={handleRequestSort}
                        from={'UserActivity'}
                    />
                    <TableBody>
                        {Object.values(auditors)?.map(
                            (data: any, index: number) => {
                                return (
                                    <TableRow key={index + 'testdata'}>
                                        {Object.values(selectField).map(
                                            (item: any, index2: number) => {
                                                return (
                                                    <BodyField
                                                        key={
                                                            index +
                                                            item.key +
                                                            ':original'
                                                        }
                                                        // openDrawer={
                                                        //     openDrawer
                                                        // }
                                                        handleSetting={
                                                            handleSetting
                                                        }
                                                        name={item.key}
                                                        row={data}
                                                        selected={selected}
                                                        setSelected={
                                                            setSelected
                                                        }
                                                        index={index}
                                                    />
                                                )
                                            },
                                        )}
                                    </TableRow>
                                )
                            },
                        )}
                    </TableBody>
                </Table>
                <TaskNoRecord record={auditors} />
            </TableContainer>
            <TablePagination
                pagination={pagination}
                setPagination={setPagination}
            />
        </>
    ) : (
        <></>
    )
}
type TableHeaderFieldProps = {
    name?: string
    row?: any
    encounterData?: any
    openDrawer?: any
    setFlagShowMsg?: any
    addendumRow?: any
    addendumIndex?: any
    border?: any
    borderbottom?: any
    borderLeft?: any
    handleSetting?: any
    selected?: any
    setSelected?: any
    index?: number
}

const BodyField = ({
    row,
    // encounterData,
    name,
    openDrawer,
    setFlagShowMsg,
    addendumRow,
    addendumIndex,
    border,
    borderbottom,
    borderLeft,
    handleSetting,
    selected,
    setSelected,
    index,
}: TableHeaderFieldProps) => {
    const [encounterData, setEncounterData] = useState<any>()
    const [encounterpause, setEncounterPause] = useState<boolean>(false)
    const navigate = useNavigate()
    // console.log('row', row)
    // console.log('managername', name)

    if (row) {
        switch (name) {
            case 'userName':
            case 'auditorName':
            case 'patientName':
            case 'firstName':
            case 'lastName':
            case 'providerName':
            case 'mobile':
            case 'secSpecialty':
            case 'primSpecialty':
            case 'assignedProvider':
                return (
                    <TableCell
                        onClick={() => {
                            setSelected(index)
                        }}
                        size="small"
                        // align={
                        //     name ===
                        //         'taskName' ||
                        //     name ===
                        //         'orgTid' ||
                        //     name === 'mrn'
                        //         ? 'left'
                        //         : 'center'
                        // }
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                            background: selected == index ? '#D3EEFB' : '',
                        }}
                    >
                        {row[name]}
                    </TableCell>
                )
            case 'email':
                return (
                    <TableCell
                        size="small"
                        width="20%"
                        // align={
                        //     name ===
                        //         'taskName' ||
                        //     name ===
                        //         'orgTid' ||
                        //     name === 'mrn'
                        //         ? 'left'
                        //         : 'center'
                        // }
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            maxWidth: 20,
                        }}
                    >
                        {row[name]}
                    </TableCell>
                )
            case 'dateCreated':
            case 'workHours':
            case 'lastLogin':
            case 'lastIdle':
                return (
                    <TableCell
                        size="small"
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                            background: selected == index ? '#D3EEFB' : '',
                        }}
                    >
                        {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null}
                    </TableCell>
                )
            case 'galenTime':
            case 'auditorTAT':
            case 'totalTAT':
                return (
                    <TableCell
                        size="small"
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                            background: selected == index ? '#D3EEFB' : '',
                        }}
                    >
                        {/* {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null} */}
                    </TableCell>
                )
            // Add other cases as needed
            case 'edit':
                return (
                    <TableCell size="medium" style={{ padding: 0 }}>
                        <IconButton
                            onClick={() => handleSetting(true, row)}
                            // size="10"
                        >
                            {/* <EditOutlinedIcon
                                style={{ width: '20px', height: '20px' }}
                            /> */}
                        </IconButton>
                    </TableCell>
                )
            default:
                return (
                    <TableCell
                        size="small"
                        align="right"
                        key={row.id}
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                            borderRight: borderLeft,
                        }}
                    >
                        <Toolbar
                            style={{ padding: 0, minHeight: 0 }}
                            sx={{ padding: 0, margin: 0 }}
                        >
                            <Tooltip title="Open Message">
                                <div>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            openDrawer(5, row)
                                            setFlagShowMsg(1)
                                        }}
                                    >
                                        <img
                                            src={MessageIcon}
                                            alt="message-icon"
                                            style={{ width: 30, height: 30 }}
                                        />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            {/* <TableThreeDotMenu
                                data={row}
                                onclick={openDrawer}
                            /> */}
                        </Toolbar>
                    </TableCell>
                )
        }
    } else {
        return <></>
    }
}

export default ManagerActivityGraph
