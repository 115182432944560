import React from 'react'
import { Button, Grid } from '@mui/material'
import { ReactComponent as AudioThumbnail } from '../../../../Assests/Icons/Audio Thumbnail.svg'
import { ReactComponent as PlayIcon } from '../../../../Assests/Icons/Play icon (3).svg'
import { useMedia } from '../../../../Services/chatService/viewMediaService'
import styles from './ViewMedia.module.css'
import {
    typeCheck,
    typeimageCheck,
    imagetypeCheck,
} from '../../../../Services/utils/ImageUtil'
import CircularProgress from '@mui/material/CircularProgress'
import ImagePreview from '../../../../Components/ImagePreview/ImagePreview'
import ShadowDrawer from '../../../../Components/ShadowDrawer/ShadowDrawer'
import AudioPlayer from '../../../../Components/AudioPlayer/AudioPlayer'
import VideoPlayer from '../../../../Components/VideoPlayer/VideoPlayer'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import Nomediaplaceholder from '../../../../Assests/Icons/Empty media icon.svg'
import {
    Search,
    SearchIconWrapper,
    StyledInputBase,
} from '../../../TaskManager/JobManagerMUIStyles'
import SearchIcon from '@mui/icons-material/Search'

interface Mediaprops {
    setFlagShowMsg: any
    providerId?: any
    subjectData?: any
    appointmentId?: any
    handleCount?: any
}

const ViewMedia = (props: Mediaprops) => {
    const {
        onScroll,
        rootref,
        mediaData,
        loader,
        thumbnailUrls,
        onErrolLoad,
        onloadimage,
        attachmentCount,
        handleFileSearch,
        searchValue,
    } = useMedia(props)
    const [selectedMedia, setSelectedMedia] = React.useState<any>(null)

    const openFilePreview = (mediaId: number) => {
        setSelectedMedia(mediaId)
    }

    const closeFilePreview = () => {
        setSelectedMedia(null)
    }

    React.useEffect(() => {
        props.handleCount(attachmentCount)
    }, [attachmentCount])

    return (
        <div className={styles.mediaRoot} onScroll={onScroll} ref={rootref}>
            <div className={styles.searchDiv}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search media…"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchValue}
                        onChange={handleFileSearch}
                    />
                </Search>
            </div>
            {loader ? (
                <Grid container spacing={4} sx={{ padding: 2 }}>
                    {mediaData &&
                        mediaData.map((media: any, index: number) => {
                            return (
                                <Grid item xs={4} key={media.id}>
                                    <div className={styles.medirowDiv}>
                                        {typeimageCheck(
                                            media?.attachments?.type?.toLowerCase(),
                                        ) && (
                                            <div
                                                className={
                                                    styles.imageContainer
                                                }
                                            >
                                                <div
                                                    onClick={() =>
                                                        openFilePreview(
                                                            media.id,
                                                        )
                                                    }
                                                >
                                                    {thumbnailUrls[index] ? (
                                                        <img
                                                            key={media.id}
                                                            onError={(e) =>
                                                                onErrolLoad(e)
                                                            }
                                                            onLoad={() =>
                                                                onloadimage()
                                                            }
                                                            crossOrigin="anonymous"
                                                            draggable={false}
                                                            src={
                                                                thumbnailUrls[
                                                                    index
                                                                ]
                                                            }
                                                            alt="attachment"
                                                            style={{
                                                                width: '120px',
                                                                height: '109px',
                                                                borderRadius:
                                                                    '7px',
                                                            }}
                                                        />
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.imageContainerOverlay
                                                            }
                                                        >
                                                            <CircularProgress />
                                                        </div>
                                                    )}
                                                </div>
                                                {media?.attachments?.type ===
                                                    'mp4' &&
                                                    thumbnailUrls[index] !==
                                                        '' &&
                                                    thumbnailUrls[index] && (
                                                        <div
                                                            className={
                                                                styles.playButtonContainer
                                                            }
                                                            onClick={() =>
                                                                openFilePreview(
                                                                    media.id,
                                                                )
                                                            }
                                                        >
                                                            <Button
                                                                className={
                                                                    styles.playButton
                                                                }
                                                            >
                                                                <PlayIcon />
                                                            </Button>
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                        {typeCheck(
                                            media?.attachments?.type,
                                        ) && (
                                            <Button
                                                className={styles.medirowDiv}
                                                onClick={() =>
                                                    openFilePreview(media.id)
                                                }
                                            >
                                                <AudioThumbnail />
                                            </Button>
                                        )}
                                    </div>
                                </Grid>
                            )
                        })}
                </Grid>
            ) : (
                <div className={styles.nomediaCenter}>
                    <img src={Nomediaplaceholder} alt="Nomediaplaceholder" />
                    <span
                        style={{ marginTop: '12px' }}
                        className={typoStyles.typoRobotoRegularprimary6614826}
                    >
                        {'No media found!'}
                    </span>
                </div>
            )}

            {selectedMedia && (
                <>
                    {mediaData.map((media: any) => {
                        if (media.id === selectedMedia) {
                            const fileType = media.attachments.type
                            if (imagetypeCheck(fileType.toLowerCase())) {
                                return (
                                    <ImagePreview
                                        key={media.id}
                                        item={media}
                                        open={true}
                                        handleClose={closeFilePreview}
                                    />
                                )
                            } else if (typeCheck(fileType)) {
                                return (
                                    <ShadowDrawer
                                        key={media.id}
                                        openDrawer={true}
                                    >
                                        <AudioPlayer
                                            key={media.id}
                                            item={media}
                                            toogleAudio={closeFilePreview}
                                        />
                                    </ShadowDrawer>
                                )
                            } else {
                                return (
                                    <VideoPlayer
                                        key={media.id}
                                        item={media}
                                        open={true}
                                        handleClose={closeFilePreview}
                                    />
                                )
                            }
                        }
                    })}
                </>
            )}
        </div>
    )
}

export default ViewMedia
