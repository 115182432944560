import { MenuItem } from '@mui/material'
import StatusDropdown from '../../../../Components/StatusDropdown'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import statusStyles from '../../../../Styles/component/StatusLabel.module.css'
import { useViewTaskStatusUpdate } from '../../../../Services/taskManagerService/viewTaskStatusUpdate'
import { useEffect, useState } from 'react'

type ViewTaskStatusUpdateProps = {
    statusMenuItem: any
    data: any
    updatedTaskList: any
    aIndex: any
}
const ViewTaskStatusUpdate = ({
    statusMenuItem,
    aIndex,
    data,
    updatedTaskList,
}: ViewTaskStatusUpdateProps) => {
    const [status, setStatus] = useState<any>(null)

    useEffect(() => {
        if (aIndex != null) {
            setStatus(data.addendumEncounters[aIndex]?.displayStatus)
            // const status2 = useViewTaskStatusUpdate(addendumData)

            // setStatus(status2)
        } else {
            setStatus(data.displayStatus)
            // const status1 = useViewTaskStatusUpdate(data)

            // setStatus(status1)
        }
    }, [data])

    return (
        <div>
            {statusMenuItem?.map((item: any) => {
                if (item.key === status) {
                    return (
                        <div
                            key={item + item.id}
                            className={statusStyles[`${item.divPrimary}`]}
                        >
                            <span className={typoStyles[`${item.typography}`]}>
                                {item.label}
                            </span>
                        </div>
                    )
                }
            })}

            {/* <StatusDropdown name={'status'} value={status}>
                {statusMenuItem?.map((item: any) => (
                    <MenuItem
                        hidden={item.key === status ? true : false}
                        sx={{ padding: '11px' }}
                        key={item.id}
                        value={item.key}
                        // onClick={() => saveStatus(item.key)}
                    >
                        <div className={statusStyles[`${item.divsScondary}`]}>
                            <span className={typoStyles[`${item.typography}`]}>
                                {item.label}
                            </span>
                        </div>
                    </MenuItem>
                ))}
            </StatusDropdown> */}
        </div>
    )
}

export default ViewTaskStatusUpdate
