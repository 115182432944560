import React from 'react'
import {
    TextField,
    Button,
    Typography,
    Dialog,
    Grid,
    MenuItem,
} from '@mui/material'
import buttonStyles from '../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../Styles/component/Typography.module.css'
import AddIcon from '@mui/icons-material/Add'
import styles from './AuditorCreation.module.css'
import {
    FormValues,
    labelMap,
    useUserCreation,
} from '../../../Services/UserServices/CreateUserService'
import UserComponent from '../../UserComponent/UserComponent'

const SpecialitySelect: React.FC<{
    id: string
    label: any
    helperText: any
    options: { id: string; name: string; desc: string }[]
    onChange: any
    value: string
    error: any
}> = ({ id, label, options, onChange, value, error, helperText }) => (
    <TextField
        id={id}
        select
        fullWidth
        name={id}
        label={label}
        // required
        onChange={onChange}
        value={value}
        error={error}
        margin="dense"
        helperText={helperText}
    >
        {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.desc}
            </MenuItem>
        ))}
    </TextField>
)
const ManagerSelect: React.FC<{
    id: string
    helperText: any
    label: string
    options: { id: string; firstName: string; lastName: string }[]
    onChange: any
    value: string
    error: any
}> = ({ id, label, options, onChange, value, error, helperText }) => (
    <TextField
        id={id}
        select
        fullWidth
        name={id}
        // required
        label={label}
        onChange={onChange}
        value={value}
        error={error}
        margin="dense"
        helperText={helperText}
    >
        {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                <UserComponent
                    detail={true}
                    auditorName={option.firstName + ' ' + option.lastName}
                    auditorId={option.id}
                />
            </MenuItem>
        ))}
    </TextField>
)
interface AuditorCreationProps {
    titleLabel: string
}
const AuditorCreation: React.FC<AuditorCreationProps> = ({ titleLabel }) => {
    const { open, formik, onClose, specialityList, managerList, onOpen } =
        useUserCreation({ titleLabel })
    const { values, errors, touched, handleChange, handleSubmit } = formik
    return (
        <div>
            <Button className={buttonStyles.primary1button} onClick={onOpen}>
                <AddIcon style={{ color: 'white' }} />
                &nbsp;&nbsp;
                <span className={typoStyles.typoRobotoMediumprimary214}>
                    {'Add User'}
                </span>
            </Button>
            <Dialog
                sx={{ zIndex: 1200 }}
                open={open}
                onClose={onClose}
                maxWidth="sm"
            >
                <form onSubmit={handleSubmit}>
                    <Grid
                        spacing={2}
                        className={styles.gridRoot}
                        container
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="h5">
                                {titleLabel === 'auditor'
                                    ? 'Add Auditor'
                                    : 'Add Auditor Manager'}
                            </Typography>
                        </Grid>
                        {Object.entries(values).map(
                            ([field, value], index: number) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={
                                        field === 'userName' ||
                                        field === 'role' ||
                                        field === 'auditorManagerId' ||
                                        field === 'password'
                                            ? 12
                                            : 6
                                    }
                                >
                                    {
                                        [
                                            'userName',
                                            'password',
                                            'firstName',
                                            'lastName',
                                            'mobile',
                                            'email',
                                        ].includes(field) ? (
                                            <TextField
                                                fullWidth
                                                id={field}
                                                name={field}
                                                autoComplete="off"
                                                label={labelMap[field]}
                                                inputProps={
                                                    field === 'mobile'
                                                        ? {
                                                              inputMode:
                                                                  'numeric',
                                                              pattern:
                                                                  '[0-9]{10,}',
                                                              maxLength: 10,
                                                          }
                                                        : {}
                                                }
                                                type={
                                                    field === 'mobile'
                                                        ? 'tel'
                                                        : field ===
                                                              'password' ||
                                                          field ===
                                                              'confirmPassword'
                                                        ? 'password'
                                                        : 'text'
                                                }
                                                value={value}
                                                onChange={handleChange}
                                                error={
                                                    touched[
                                                        field as keyof FormValues
                                                    ] &&
                                                    Boolean(
                                                        errors[
                                                            field as keyof FormValues
                                                        ],
                                                    )
                                                }
                                                helperText={
                                                    touched[
                                                        field as keyof FormValues
                                                    ] &&
                                                    errors[
                                                        field as keyof FormValues
                                                    ]
                                                        ? errors[
                                                              field as keyof FormValues
                                                          ]
                                                        : ''
                                                }
                                                margin="dense"
                                            />
                                        ) : [
                                              'mainSpecialityId',
                                              //   'secondarySpecialityId',
                                          ].includes(field) ? (
                                            values['role'] === 'auditor' && (
                                                <SpecialitySelect
                                                    id={field}
                                                    label={labelMap[field]}
                                                    options={specialityList}
                                                    // options={specialityList?.filter(
                                                    //     (item: any) =>
                                                    //         field ===
                                                    //         'mainSpecialityId'
                                                    //             ? item.id !==
                                                    //               values[
                                                    //                   'secondarySpecialityId'
                                                    //               ]
                                                    //             :
                                                    //         item.id !==
                                                    //         values[
                                                    //             'mainSpecialityId'
                                                    //         ],
                                                    // )}
                                                    onChange={handleChange}
                                                    value={value}
                                                    error={
                                                        touched[
                                                            field as keyof FormValues
                                                        ] &&
                                                        Boolean(
                                                            errors[
                                                                field as keyof FormValues
                                                            ],
                                                        )
                                                    }
                                                    helperText={
                                                        touched[
                                                            field as keyof FormValues
                                                        ] &&
                                                        errors[
                                                            field as keyof FormValues
                                                        ]
                                                            ? errors[
                                                                  field as keyof FormValues
                                                              ]
                                                            : ''
                                                    }
                                                />
                                            )
                                        ) : (
                                            field === 'auditorManagerId' &&
                                            values['role'] === 'auditor' && (
                                                <ManagerSelect
                                                    id={field}
                                                    label={labelMap[field]}
                                                    options={managerList}
                                                    onChange={handleChange}
                                                    value={value}
                                                    error={
                                                        touched[
                                                            field as keyof FormValues
                                                        ] &&
                                                        Boolean(
                                                            errors[
                                                                field as keyof FormValues
                                                            ],
                                                        )
                                                    }
                                                    helperText={
                                                        touched[
                                                            field as keyof FormValues
                                                        ] &&
                                                        errors[
                                                            field as keyof FormValues
                                                        ]
                                                            ? errors[
                                                                  field as keyof FormValues
                                                              ]
                                                            : ''
                                                    }
                                                />
                                            )
                                        )
                                        // field === 'role' && (
                                        //     <TextField
                                        //         select
                                        //         fullWidth
                                        //         id={field}
                                        //         name={field}
                                        //         label={labelMap[field]}
                                        //         defaultValue={roleData[0].value}
                                        //         onChange={handleChange}
                                        //         value={value}
                                        //     >
                                        //         {roleData.map((option) => (
                                        //             <MenuItem key={option.id} value={option.value}>
                                        //                 {option.label}
                                        //             </MenuItem>
                                        //         ))}
                                        //     </TextField>
                                        // )
                                    }
                                </Grid>
                            ),
                        )}
                        <Grid item xs={12} className={styles.row}>
                            <Button
                                className={buttonStyles.cancelbutton}
                                onClick={onClose}
                            >
                                <span
                                    className={
                                        typoStyles.typoRobotoRegoularprimary5914
                                    }
                                >
                                    Cancel
                                </span>
                            </Button>
                            <Button
                                type="submit"
                                className={buttonStyles.yesbutton}
                            >
                                <span
                                    className={
                                        typoStyles.typoRobotoMediumprimary214
                                    }
                                >
                                    Submit
                                </span>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>
        </div>
    )
}

export default AuditorCreation
