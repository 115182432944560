import React, { useState } from 'react'
import { Button } from '@mui/material'
import styles from './ViewFile.module.css'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import { useFile } from '../../../../Services/chatService/viewFileService'
import { renderImg } from '../../../../Services/utils/ImageUtil'
import CSVViewer from '../../../../Components/CSVViewer/CSVViewer'
import DocViewer from '../../../../Components/DocViewer/DocViewer'
import PDFviewer from '../../../../Components/PDFviewer/PDFviewer'
import Nofileplaceholder from '../../../../Assests/Icons/Empty file icon.svg'
import {
    Search,
    SearchIconWrapper,
    StyledInputBase,
} from '../../../TaskManager/JobManagerMUIStyles'
import SearchIcon from '@mui/icons-material/Search'
interface FileProps {
    setFlagShowMsg: any
    providerId?: any
    subjectData?: any
    appointmentId?: any
}

const ViewFile = (props: FileProps) => {
    const {
        onScroll,
        rootref,
        fileData,
        loader,
        handleFileSearch,
        searchValue,
    } = useFile(props)
    const [openPreview, setOpenPreview] = useState<number | null>(null)

    const openFilePreview = (mediaId: number) => {
        setOpenPreview(mediaId)
    }

    const closeFilePreview = () => {
        setOpenPreview(null)
    }

    const formatFileSize = (sizeInBytes: any) => {
        const kilobyte = 1024
        const megabyte = kilobyte * 1024

        if (sizeInBytes >= megabyte) {
            return `${(sizeInBytes / megabyte).toFixed(2)} MB`
        } else if (sizeInBytes >= kilobyte) {
            return `${(sizeInBytes / kilobyte).toFixed(2)} KB`
        } else {
            return `${sizeInBytes} bytes`
        }
    }

    const fileNametag = (name: string) => {
        const maxLength = 25
        const formattedName = name?.split('.')[0]

        if (formattedName?.length > maxLength) {
            return `${formattedName?.slice(0, maxLength)}...`
        }

        return formattedName
    }

    return (
        <div
            className={`${styles.cardRoot} ${
                fileData.length > 0 ? styles.withPadding : styles.noPadding
            }`}
            onScroll={onScroll}
            ref={rootref}
        >
            <div className={styles.searchDiv}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search file..."
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchValue}
                        onChange={handleFileSearch}
                    />
                </Search>
            </div>
            {loader ? (
                fileData &&
                fileData.map((media: any) => (
                    <Button
                        className={`${styles.cardDiv} ${styles.fileItem}`}
                        key={media.id}
                        onClick={() => openFilePreview(media.id)}
                    >
                        <div className={styles.rowDiv}>
                            <div className={styles.svgStyles}>
                                <img
                                    draggable={false}
                                    src={renderImg(media?.attachments?.type)}
                                    alt="attachment"
                                    width={28}
                                    height={36}
                                />
                            </div>
                            <div className={styles.details}>
                                <span
                                    className={
                                        typoStyles.typoRobotoBoldprimary718
                                    }
                                >
                                    {fileNametag(media?.attachments?.fileName)}
                                </span>
                                <div className={styles.detailrow}>
                                    <span
                                        className={
                                            typoStyles.typoRobotoRegoularprimary681621
                                        }
                                    >
                                        {formatFileSize(
                                            media?.attachments?.size,
                                        )}
                                    </span>
                                    <div className={styles.dot}></div>
                                    <span
                                        className={`${typoStyles.typoRobotoRegoularprimary681621} ${styles.lowercase}`}
                                    >
                                        {media?.attachments?.type}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Button>
                ))
            ) : (
                <div className={styles.noFileCenter}>
                    <img src={Nofileplaceholder} alt="Nofileplaceholder" />
                    <span
                        style={{ marginTop: '12px' }}
                        className={typoStyles.typoRobotoRegularprimary6611826}
                    >
                        {'No files found!'}
                    </span>
                </div>
            )}

            {openPreview && (
                <>
                    {fileData.map((media: any) => {
                        if (media.id === openPreview) {
                            const fileType = media.attachments.type
                            if (fileType === 'csv') {
                                return (
                                    <CSVViewer
                                        key={media.id}
                                        item={media}
                                        open={true}
                                        handleClose={closeFilePreview}
                                    />
                                )
                            } else if (fileType === 'pdf') {
                                return (
                                    <PDFviewer
                                        key={media.id}
                                        item={media}
                                        open={true}
                                        handleClose={closeFilePreview}
                                    />
                                )
                            } else if (fileType === 'docx') {
                                return (
                                    <DocViewer
                                        key={media.id}
                                        item={media}
                                        open={true}
                                        handleClose={closeFilePreview}
                                    />
                                )
                            }
                        }
                    })}
                </>
            )}
        </div>
    )
}

export default ViewFile
