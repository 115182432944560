import React, { useState } from 'react'
import { useTaskmanager } from '../../../Services/taskManagerService/taskManagerService'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material'
import TableHeader from '../../../Components/TableHeader'
import TableBodyField from '../../TaskManager/Component/TableBodyField/TableBodyField'
import TaskNoRecord from '../../../Components/TaskNoRecord/TaskNoRecord'
import moment from 'moment'
import { Today } from '@mui/icons-material'
import TATMeterPlotTable from '../../../Components/TATMeter/TATMeterPlotTable'

interface TATMeterProviderProps {
    providerName?: string
    contractClient?: string
    cityState?: string
    siteOfService?: string
    specialty?: string
    assignedAuditors?: Array<string>
    numRecordingsSubmitted?: number
    averageTAT?: number
}
function TATMeterProvider() {
    const {
        sortingObj,
        bubblechartData,
        barchartDataCount,
        barchartData,
        chartFilter,
        filterMenuChange,
        filterOnChange,
        filterSearchByField,
        searchByName,
        auditors,
        updatedTaskList,
        closeTaskDrawer,
        task,
        addendumIndex,
        // aTask,
        enableModel,
        name,
        openFilter,
        filterFlag,
        pagination,
        setPagination,
        taskList,
        filteredValue,
        filterdHeader,
        handleRequestSort,
        openDrawer,
        openModel,
        modalTranscriptClose,
        transcriptmodal,
        openTranscriptModel,
        openFillablePdfModel,
        fillablePdfmodal,
        openDocuemt3PdfModel,
        encounterData,
        addendumEncounterData,
        statusCount,
        filterButton,
        setFilterButton,
    } = useTaskmanager()

    const [chatnavigation, setFlagShowMsg] = useState<any>(null)
    const [testData] = useState<Array<TATMeterProviderProps>>([
        {
            providerName: 'PN',
            contractClient: 'CC',
            cityState: 'Austin, Texas',
            siteOfService: 'SOS',
            specialty: 'Specialty',
            assignedAuditors: ['auditor1', 'auditor2'],
            numRecordingsSubmitted: 30,
            averageTAT: 35,
        },
        {
            providerName: 'PN2',
            contractClient: 'CC2',
            cityState: 'Austin, Texas',
            siteOfService: 'SOS2',
            specialty: 'Specialty2',
            assignedAuditors: ['auditor3'],
            numRecordingsSubmitted: 35,
            averageTAT: 25,
        },
    ])

    return (
        <>
            <TableContainer sx={{ height: '70vh' }}>
                <Table
                    stickyHeader
                    // sx={{ width: '10dvw', borderBlock: 'black' }}
                    aria-label="simple table"
                >
                    <TableHeader
                        sortingObj={sortingObj}
                        handleRequestSort={handleRequestSort}
                        from={'TATMeterProvider'}
                    />
                    <TableBody>
                        {testData.map((data: any, index: number) => {
                            // console.log('data', data)

                            return (
                                <TableRow key={index + 'testdata'}>
                                    {Object.keys(data).map(
                                        (testDataKeys: any) => {
                                            switch (testDataKeys) {
                                                case 'firstName':
                                                case 'lastName':
                                                case 'providerName':
                                                case 'specialty':
                                                case 'primSepcialty':
                                                case 'secSpecialty':
                                                case 'contractClient':
                                                case 'cityState':
                                                case 'siteOfService':
                                                case 'totalJobs':
                                                case 'numRecordingsSubmitted':
                                                    return (
                                                        <TableCell
                                                            size="small"
                                                            // align={
                                                            //     name ===
                                                            //         'taskName' ||
                                                            //     name ===
                                                            //         'orgTid' ||
                                                            //     name === 'mrn'
                                                            //         ? 'left'
                                                            //         : 'center'
                                                            // }
                                                            sx={{
                                                                border: '0.5px solid #A0A0A0',
                                                                whiteSpace:
                                                                    'wrap',
                                                            }}
                                                        >
                                                            {data[testDataKeys]}
                                                        </TableCell>
                                                    )

                                                case 'assignedAuditors':
                                                    return (
                                                        <TableCell
                                                            size="small"
                                                            sx={{
                                                                border: '0.5px solid #A0A0A0',
                                                                whiteSpace:
                                                                    'wrap',
                                                            }}
                                                        >
                                                            {data[
                                                                testDataKeys
                                                            ].join(', ')}
                                                        </TableCell>
                                                    )
                                                case 'lastLogin':
                                                    return (
                                                        <TableCell
                                                            size="small"
                                                            sx={{
                                                                border: '0.5px solid #A0A0A0',
                                                                whiteSpace:
                                                                    'wrap',
                                                            }}
                                                        >
                                                            {data[testDataKeys]
                                                                ? moment(
                                                                      data[
                                                                          testDataKeys
                                                                      ],
                                                                  ).format(
                                                                      'DD/MMM/yyyy',
                                                                  )
                                                                : null}
                                                        </TableCell>
                                                    )
                                                case 'averageTAT':
                                                    return (
                                                        <TableCell
                                                            size="small"
                                                            align="left"
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                                border: '0.5px solid #A0A0A0',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '10dvw',
                                                                    // height: '5dvh',
                                                                }}
                                                            >
                                                                <TATMeterPlotTable
                                                                    TATData={
                                                                        data[
                                                                            testDataKeys
                                                                        ]
                                                                    }
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    )

                                                // return <></>
                                            }
                                        },
                                    )}
                                </TableRow>
                            )
                        })}
                        {/* {testData.map(indvData, index)=>{
                            
                            return(
                            <TableRow>
                            {data.map(
                                (data: string, index: number) => {
                                    return (
                                        <TableCell key={data + index}>
                                            {
                                                testData[
                                                    data as keyof TATMeterAuditorProps
                                                ]
                                            }
                                        </TableCell>
                                    )
                                },
                            )}
                        </TableRow>)
                        }} */}
                    </TableBody>
                    {/* <TableBodyField
                        from={'TATMeterAuditor'}
                        filteredTask={[
                            {
                                firstName: 'FN',
                                lastName: 'LN',
                                prviderName: 'PN',
                                primSepcialty: 'PS',
                                secSpecialty: 'SS',
                                totalJobs: 'TJ',
                            },
                        ]}
                        // addendumData={addendumEncounterData}
                        openDrawer={openDrawer}
                        modalOpen={openModel}
                        openTranscriptModel={openTranscriptModel}
                        setFlagShowMsg={setFlagShowMsg}
                        // openFillablePdfModel={openFillablePdfModel}
                        // openDocuemt3PdfModel={openDocuemt3PdfModel}
                    /> */}
                </Table>
                <TaskNoRecord record={testData} />
            </TableContainer>
        </>
    )
}

export default TATMeterProvider
