import React from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist-min'
import styles from './TATMeter.module.css'

interface TATMeterProps {
    TATData?: any
}
function TATMeterPlotTable(props: TATMeterProps) {
    const Plot = createPlotlyComponent(Plotly)
    const data = [
        {
            domain: { x: [0, 1], y: [0, 1] },
            value: props.TATData,
            // title: { text: 'TAT-Meter' },
            type: 'indicator',
            mode: 'gauge+number',
            // delta: { reference: 20 },
            gauge: {
                axis: { range: [null, 90], showticklabels: false, ticklen: 0 },
                steps: [
                    { range: [0, 30], color: 'lightgray' },
                    { range: [30, 60], color: 'gray' },
                ],
                bar: {
                    thickness: 1,
                },
                // threshold: {
                //     line: { color: 'red', width: 4 },
                //     thickness: 0.75,
                //     value: 30,
                // },
            },
        },
    ]

    const layout = {
        // margin: { t: 60, b: 20 },
        margin: { t: 10, b: 10, l: 10, r: 10 },
        // width: '1dvw',
        height: 55,
    }
    // Plotly.newPlot('myDiv', data, layout);

    return (
        <Plot
            className={styles.TATMeterTableContainer}
            data={data}
            layout={layout}
            config={{ responsive: true }}
        />
    )
}

export default TATMeterPlotTable
