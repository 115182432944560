import React from 'react'
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider'
import {
    auditorListAPI,
    auditorSerachListAPI,
    taskListAPI,
} from '../api/auditorAPIService'
import { auditorListReduce } from '../reducer/auditorReducer'
import { taskListReduce } from '../reducer/taskReducer'
import { pageParams, paramsObj, taskListPageParams } from '../paginationService'
import { postListPRTAT } from '../api/phraseAPIService'
import axios from 'axios'
import { getAuditors } from '../api/taskAPIService'

let controller = axios.CancelToken.source()

export function useAuditorManager() {
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [auditorListBool, setAuditorListBool] = React.useState(false)
    const [auditorList, setAuditorList] = React.useReducer(
        auditorListReduce,
        [],
    )
    const [auditorData, setAuditorData] = React.useState<any>(null)
    const [taskList, setTaskList] = React.useReducer(taskListReduce, [])
    const [selectedTaskItem, setSelectedTaskItem] = React.useState<any>(null)
    const [task, setTask] = React.useState<any>(null)
    const [openSetting, setOpenSetting] = React.useState<any>(false)
    const [enbaleModel, setEnbaleModel] = React.useState<any>(null)
    const [enbaleTaskModel, setEnbaleTaskModel] = React.useState<any>(null)
    const [name, setName] = React.useState('')
    const [pagination, setPagination] = React.useState(pageParams)
    const [listPagination, setListPagination] =
        React.useState(taskListPageParams)
    const listInnerRef = React.useRef(null)

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current
            if (scrollTop + clientHeight === scrollHeight) {
                setListPagination({
                    ...listPagination,
                    count: listPagination.count,
                    currentPage: listPagination.currentPage + 1,
                })
            }
        }
    }

    React.useEffect(() => {
        const GetPRList = async () => {
            controller = axios.CancelToken.source()

            const PRList = await postListPRTAT({}, controller.token)
            PRList?.recordingCounts?.map((data: any, index: number) => {
                setAuditorList({
                    payload: data,
                    type: 'update2',
                })
            })
        }
        GetPRList()
    }, [auditorListBool])
    React.useEffect(() => {
        async function initiate() {
            await auditorListAPICall(name, pagination.currentPage)
        }
        initiate()
    }, [pagination.count, pagination.currentPage])
    React.useEffect(() => {
        async function initiate() {
            if (
                enbaleModel &&
                selectedTaskItem?.auditorId &&
                listPagination.currentPage > 1
            ) {
                await taskListAPICall(
                    enbaleModel,
                    listPagination.currentPage,
                    selectedTaskItem?.auditorId,
                )
            }
        }
        initiate()
    }, [listPagination.count, listPagination.currentPage])
    const auditorListAPICall = async (name: any, page: number) => {
        // console.log('auditorListAPI', name, page)
        if (name.length > 0 && name !== '') {
            paramsObj.pageSize = pagination.count
            paramsObj.pageNumber = page
            paramsObj.role = 'auditor'
            paramsObj.searchValue = name
            const auditors: any = await getAuditors()
            const data = await auditorSerachListAPI(paramsObj)
            responseSet(data, auditors)
            return data
        } else {
            loaderSetting(true)
            const auditors: any = await getAuditors()
            const data = await auditorListAPI({
                role: 'auditor',
                pageNumber: page,
                pageSize: pagination.count,
            })
            responseSet(data, auditors)
            return data
        }
    }
    const taskListAPICall = async (
        key: number,
        page: number,
        auditorId: any,
    ) => {
        loaderSetting(true)
        const bodyObj = {
            auditorId,
            taskAssigned: key === 1 ? true : false,
            taskCompleted: key === 2 ? true : false,
            overDueTask: key === 3 ? true : false,
            pageSize: listPagination.count,
            pageNumber: page,
        }
        const data = await taskListAPI(bodyObj)
        responseTaskListSet(data, key)
    }
    const responseSet = (data: any, auditorData?: any) => {
        // console.log('responseset', data, auditorData)
        loaderSetting(false)
        if (data?.status) {
            setAuditorList({ payload: data?.result, type: 'add' })
            setAuditorListBool(!auditorListBool)
            setPagination({
                ...pagination,
                currentPage: data?.currentPage,
                totalPages: data?.totalPages,
                totalRecords: data?.totalRecords,
            })
        } else {
            setAuditorList({ payload: [], type: 'add' })
            setAuditorListBool(!auditorListBool)

            setPagination(pageParams)
            snackbarSetting(true, data?.message, 'error')
        }
    }
    const responseTaskListSet = (data: any, key: number) => {
        loaderSetting(false)
        if (data?.status) {
            setTaskList({ payload: data?.result, type: 'add' })
            setEnbaleModel(key)
            setListPagination({
                ...listPagination,
                currentPage: data?.currentPage,
                totalRecords: data?.totalRecords,
            })
        } else {
            snackbarSetting(true, data?.message, 'error')
        }
    }
    const searchByName = async (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        setName(e.target.value)
        await auditorListAPICall(e.target.value, 1)
    }
    const openDrawer = async (key: number, data: any) => {
        setTaskList({ payload: [], type: 'removeall' })
        setSelectedTaskItem(data)
        await taskListAPICall(key, 1, data?.auditorId)
    }
    const openViewDrawer = async (data: any) => {
        if (data) {
            setTask(data)
            setEnbaleTaskModel(1)
        }
    }
    const editDrawer = () => {
        setEnbaleTaskModel(2)
    }
    const closeTaskDrawer = () => {
        setEnbaleTaskModel(null)
    }
    const closeDrawer = () => {
        setEnbaleModel(null)
    }
    const updatedTaskList = async (data: any) => {
        if (enbaleModel === 3 && data?.status === 'claim submitted') {
            setTaskList({ payload: data, type: 'remove' })
            setListPagination({
                ...listPagination,
                totalRecords: listPagination.totalRecords - 1,
            })
        } else {
            setTaskList({ payload: data, type: 'update' })
        }
        setTask(data)
        await auditorListAPICall(name, pagination.currentPage)
    }

    const handleSetting = (flag: boolean, data?: any) => {
        setOpenSetting(flag)
        setAuditorData(data)
    }
    const updateAuditorList = (data: any) => {
        setAuditorList({ payload: data[0], type: 'update' })
        setAuditorListBool(!auditorListBool)
    }
    return {
        updateAuditorList,
        auditorData,
        handleSetting,
        openSetting,
        auditorList,
        pagination,
        setPagination,
        searchByName,
        name,
        openDrawer,
        taskList,
        enbaleModel,
        listPagination,
        listInnerRef,
        onScroll,
        selectedTaskItem,
        openViewDrawer,
        task,
        closeDrawer,
        editDrawer,
        updatedTaskList,
        enbaleTaskModel,
        closeTaskDrawer,
    }
}
