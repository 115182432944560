import React, { useState } from 'react'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import typoStyles from '../../../Styles/component/Typography.module.css'
import { DateRangePicker, DefinedRange } from 'react-date-range'
import enUS from 'date-fns/locale/en-US'
import moment from 'moment'
import {
    Button,
    CircularProgress,
    IconButton,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
} from '@mui/material'
import MessageIcon from '../../../Assests/Icons/MessageIcon.png'
import EventIcon from '@mui/icons-material/Event'
import { useDispatch, useSelector } from 'react-redux'
import {
    productionDateEndSetting,
    productionDateSetting,
} from '../../../Services/actions/productionDateAction'
import { useNavigate } from 'react-router-dom'
import { useTableService } from '../../../Services/taskManagerService/tableService'
import { useTaskmanager } from '../../../Services/taskManagerService/taskManagerService'
import { useAuditorManager } from '../../../Services/auditorManagerService/auditorManagerService'
import ManagerActivityGraph from './ManagerActivityGraph'

interface RangeSelection {
    startDate?: Date
    endDate?: Date
    key: string
}
function ManagerActivitySummary() {
    const { sortingObj, auditors, handleRequestSort, taskList } =
        useTaskmanager()
    const {
        updateAuditorList,
        auditorList,
        pagination,
        setPagination,
        searchByName,
        // name,
        // openDrawer,
        // taskList,
        enbaleModel,
        listPagination,
        listInnerRef,
        onScroll,
        selectedTaskItem,
        openViewDrawer,
        task,
        closeDrawer,
        editDrawer,
        updatedTaskList,
        enbaleTaskModel,
        closeTaskDrawer,
        handleSetting,
        openSetting,
        auditorData,
    } = useAuditorManager()

    const dispatch = useDispatch()
    const { selectField } = useTableService({
        from: 'JobReport',
    })

    const [selectionRange, setSelectionRange] = useState<RangeSelection>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    )
    const [selected, setSelected] = useState(0)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelect = (ranges: any) => {
        const newStartDate = new Date(ranges.selection.startDate)
        const newEndDate = new Date(ranges.selection.endDate)
        dispatch(productionDateSetting(newStartDate))
        dispatch(productionDateEndSetting(newEndDate))
        setSelectionRange(ranges.selection)
    }
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    return auditors ? (
        <div>
            <div>
                <b>Date Range:</b>
                {moment(selectionRange.startDate).format('ll') +
                    '-' +
                    moment(selectionRange.endDate).format('ll')}
                <IconButton
                    aria-describedby={id}
                    // variant="contained"
                    onClick={handleClick}
                >
                    <EventIcon />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <DateRangePicker
                        locale={enUS}
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                    />
                </Popover>
            </div>
            <div style={{ display: 'flex', gap: 10 }}>
                <div style={{ width: '50dvw', paddingLeft: 10 }}>
                    <ManagerActivityGraph
                        sortingObj={sortingObj}
                        auditors={auditors}
                        handleRequestSort={handleRequestSort}
                        handleSetting={handleSetting}
                        pagination={pagination}
                        setPagination={setPagination}
                        selected={selected}
                        setSelected={setSelected}
                    />
                </div>
                <div
                    style={{
                        width: '50dvw',
                        background: '#F0FAFF',
                        borderRadius: 20,
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    {auditors[selected]?.lastName}
                </div>
            </div>
        </div>
    ) : (
        <></>
    )
}

export default ManagerActivitySummary
