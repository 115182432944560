import {
    Button,
    Typography,
    Box,
    Tabs,
    Tab,
    IconButton,
    Grid,
    Tooltip,
    Grid2,
} from '@mui/material'
import buttonStyles from '../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../Styles/component/Typography.module.css'
import { statusTagMenuItem } from '../../../Services/utils/constant'
import styles from '../JobManager.module.css'
import {
    useViewtask,
    ViewTaskProps,
} from '../../../Services/taskManagerService/viewTaskService'
import ViewTaskStatusUpdate from './ViewTaskStatusUpdate/ViewTaskStatusUpdate'
import ViewAudios from './ViewAudios/ViewAudios'
import { ReactComponent as MessageIcon } from '../../../Assests/Icons/Icons svg/Message.svg'
import moment from 'moment'
import TaskdrawerHeader from '../../../Components/TaskdrawerHeader/TaskdrawerHeader'
import { TabPanel, a11yProps } from '../../../Components/DrawerTab/DrawerTab'
import ViewField from '../../../Components/ViewField/ViewField'
import ViewUserTag from '../../../Components/ViewUserTag/ViewUserTag'
import Badge from '@mui/material/Badge'
import Comments from './comments/comments'
import DocumentComponent from '../../../Components/DocumentComponent/DocumentComponent'
import { useSelector } from 'react-redux'

export default function ViewTask(props: ViewTaskProps) {
    // console.log('props', props)
    const userRole = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.userRole,
    )

    const {
        unreadCount,
        setTableValue,
        chatnavigation,
        setFlagShowMsg,
        setStates,
        handleTabChange,
        tableValue,
        states,
    } = useViewtask(props)
    if (!states) {
        return null
    }
    const {
        providerName,
        auditorName,
        status,
        location,
        orgTid,
        speciality,
        type,
        endDate,
        startDate,
        patientAccount,
        patientGender,
        patientName,
        mrn,
        encounter,
        patientdob,
        appointmentId,
        scheduleId,
        encounterId,
        cqProviderName,
        siteOfService,
        cityState,
        taskName,
        admissionDate,
        dischargeDate,
    } = states

    return (
        <div>
            <TaskdrawerHeader
                title={states.taskName}
                onclick={() => {
                    if (props.menuHandleCloseClick) {
                        setStates(null)
                        setFlagShowMsg(null)
                        setTableValue(0)
                        props.menuHandleCloseClick()
                    }
                }}
            />
            {!chatnavigation && (
                <div className={styles.HeaderDiv}>
                    <ViewTaskStatusUpdate
                        statusMenuItem={statusTagMenuItem}
                        aIndex={props?.aIndex}
                        data={states}
                        updatedTaskList={props?.updatedTaskList}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Badge
                            badgeContent={unreadCount}
                            color="error"
                            overlap="circular"
                            sx={{ marginRight: '-36px', marginTop: '8px' }}
                        />
                        <Tooltip title="Open Comment">
                            <IconButton
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    marginRight: '20px',
                                }}
                                size="small"
                                onClick={() => setFlagShowMsg(1)}
                            >
                                <MessageIcon
                                    width={50}
                                    height={50}
                                    style={{ position: 'fixed' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )}

            {!chatnavigation ? (
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            variant="fullWidth"
                            value={tableValue}
                            centered
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                        >
                            <Tab
                                sx={{ textTransform: 'initial' }}
                                className={typoStyles.typoRobotoMedium1633}
                                label="View Details"
                                {...a11yProps(0)}
                            />
                            {!props.encounterData?.codeQ ? (
                                <Tab
                                    sx={{ textTransform: 'initial' }}
                                    className={typoStyles.typoRobotoMedium1633}
                                    label="Audios"
                                    {...a11yProps(1)}
                                />
                            ) : (
                                <></>
                            )}

                            {props.encounterData?.galen_output != null ? (
                                props.encounterData?.galen_output['2C'] ||
                                props.addendumData?.galen_output['2C'] ? (
                                    <Tab
                                        sx={{ textTransform: 'initial' }}
                                        className={
                                            typoStyles.typoRobotoMedium1633
                                        }
                                        label="Documents"
                                        {...a11yProps(2)}
                                    />
                                ) : null
                            ) : null}
                        </Tabs>
                    </Box>
                    {!props?.encounterData?.codeQ ? (
                        <TabPanel value={tableValue} index={0}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    height: 'calc(100vh - 200px',
                                }}
                            >
                                <div style={{ padding: '5px 20px' }}>
                                    <Grid2 container spacing={0.5}>
                                        {auditorName && (
                                            <ViewUserTag
                                                name={'Auditor'}
                                                value={auditorName}
                                                userId={states.auditorId}
                                            />
                                        )}
                                        {orgTid && (
                                            <ViewField
                                                name={'Organization TIN'}
                                                value={orgTid}
                                            />
                                        )}
                                        {location && (
                                            <ViewField
                                                name={'Location'}
                                                value={location}
                                            />
                                        )}
                                        {providerName && (
                                            <ViewUserTag
                                                name={'Provider Name'}
                                                value={providerName}
                                                userId={states.providerId}
                                            />
                                        )}
                                        {speciality && (
                                            <ViewField
                                                name={'Speciality'}
                                                value={speciality}
                                            />
                                        )}
                                        {patientName && (
                                            <ViewUserTag
                                                name={'Patient Name'}
                                                value={patientName}
                                                userId={states.appointmentId}
                                            />
                                        )}
                                        {mrn && (
                                            <ViewField
                                                name={'MRN'}
                                                value={mrn}
                                            />
                                        )}
                                        {patientAccount && (
                                            <ViewField
                                                name={'Patient Account'}
                                                value={patientAccount}
                                            />
                                        )}
                                        {patientdob && (
                                            <ViewField
                                                name={'Patient DOB'}
                                                value={moment(
                                                    patientdob,
                                                ).format('DD-MMM-YYYY')}
                                            />
                                        )}
                                        {patientGender && (
                                            <ViewField
                                                name={'Patient Gender'}
                                                value={
                                                    patientGender === 'F'
                                                        ? 'Female'
                                                        : 'Male'
                                                }
                                            />
                                        )}
                                        {encounter > 0 && encounter && (
                                            <ViewField
                                                name={'Encounter Collection'}
                                                value={encounter}
                                            />
                                        )}
                                        {startDate && (
                                            <ViewField
                                                name={'Start Date'}
                                                value={moment(startDate).format(
                                                    'DD-MMM-YYYY',
                                                )}
                                            />
                                        )}
                                        {endDate && (
                                            <ViewField
                                                name={'End Date'}
                                                value={moment(endDate).format(
                                                    'DD-MMM-YYYY',
                                                )}
                                            />
                                        )}
                                        {type && (
                                            <ViewField
                                                name={'Appointment Type'}
                                                value={type}
                                            />
                                        )}
                                    </Grid2>
                                    {states?.id !== '' &&
                                        props.showEditbtn &&
                                        userRole !== 'auditor' &&
                                        status !== 'claim submitted' && (
                                            <div
                                                style={{
                                                    height: '120px',
                                                    display: 'flex',
                                                    marginBottom: '40px',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Button
                                                    className={
                                                        buttonStyles.yesbutton
                                                    }
                                                    disabled={
                                                        status ===
                                                        'claim submitted'
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        props.openEdit(states)
                                                    }}
                                                >
                                                    <Typography
                                                        className={
                                                            typoStyles.typoRobotoMediumprimary214
                                                        }
                                                    >
                                                        {'Edit'}
                                                    </Typography>
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </TabPanel>
                    ) : (
                        <TabPanel value={tableValue} index={0}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    height: 'calc(100vh - 200px',
                                }}
                            >
                                <div style={{ padding: '5px 20px' }}>
                                    <Grid2 container spacing={0.5}>
                                        {location && (
                                            <ViewField
                                                name={'Contract Client'}
                                                value={location}
                                            />
                                        )}

                                        {siteOfService && (
                                            <ViewField
                                                name={'Site of Service'}
                                                value={siteOfService}
                                            />
                                        )}
                                        {cityState && (
                                            <ViewField
                                                name={'City, State'}
                                                value={cityState}
                                            />
                                        )}
                                        {cqProviderName && (
                                            <ViewUserTag
                                                name={'Provider Name'}
                                                value={cqProviderName}
                                                userId={states.providerId}
                                            />
                                        )}
                                        {appointmentId && (
                                            <ViewField
                                                name={'Visit Number'}
                                                value={appointmentId}
                                            />
                                        )}

                                        {/* {patientdob && (
                                            <ViewField
                                                name={'Patient DOB'}
                                                value={moment(
                                                    patientdob,
                                                ).format('DD-MMM-YYYY')}
                                            />
                                        )} */}
                                        {/* {patientGender && (
                                            <ViewField
                                                name={'Patient Gender'}
                                                value={
                                                    patientGender === 'F'
                                                        ? 'Female'
                                                        : 'Male'
                                                }
                                            />
                                        )} */}
                                        {/* {encounter > 0 && encounter && (
                                            <ViewField
                                                name={'Encounter Collection'}
                                                value={encounter}
                                            />
                                        )} */}
                                        {admissionDate && (
                                            <ViewField
                                                name={'Admission Date'}
                                                value={moment(
                                                    admissionDate,
                                                ).format('DD-MMM-YYYY')}
                                            />
                                        )}
                                        {dischargeDate && (
                                            <ViewField
                                                name={'Discharge Date'}
                                                value={moment(
                                                    dischargeDate,
                                                ).format('DD-MMM-YYYY')}
                                            />
                                        )}
                                        {taskName && (
                                            <ViewField
                                                name={'Reason for Stay'}
                                                value={taskName}
                                            />
                                        )}
                                    </Grid2>
                                    {states?.id !== '' &&
                                        props.showEditbtn &&
                                        userRole !== 'auditor' &&
                                        status !== 'claim submitted' && (
                                            <div
                                                style={{
                                                    height: '120px',
                                                    display: 'flex',
                                                    marginBottom: '40px',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Button
                                                    className={
                                                        buttonStyles.yesbutton
                                                    }
                                                    disabled={
                                                        status ===
                                                        'claim submitted'
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        props.openEdit(states)
                                                    }}
                                                >
                                                    <Typography
                                                        className={
                                                            typoStyles.typoRobotoMediumprimary214
                                                        }
                                                    >
                                                        {'Edit'}
                                                    </Typography>
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </TabPanel>
                    )}
                    <TabPanel value={tableValue} index={1}>
                        <ViewAudios
                            appointmentId={appointmentId}
                            scheduleId={scheduleId}
                            encounterId={encounterId}
                        />
                    </TabPanel>
                    <TabPanel value={tableValue} index={2}>
                        <DocumentComponent
                            encounterData={props.encounterData}
                            addendumData={props.addendumData}
                            appointmentId={appointmentId}
                            scheduleId={scheduleId}
                            encounterId={encounterId}
                            aIndex={props?.aIndex}
                        />
                    </TabPanel>
                </Box>
            ) : (
                <Comments
                    task={states}
                    updatedTaskList={props.updatedTaskList}
                    showHeader={true}
                />
            )}
        </div>
    )
}
