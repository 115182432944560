import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getManagers } from '../api/taskAPIService'
import { createUser, getSpeciality } from '../api/userAPIService'
import {
    AuditorlistProps,
    SpecialitylistProps,
} from '../types/taskmanagertypes'
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider'
import { useSelector } from 'react-redux'

export interface FormValues {
    userName: string
    password: string
    email: string
    mobile: string
    mainSpecialityId: string | undefined
    // secondarySpecialityId: string | undefined
    firstName: string
    lastName: string
    role: string
    auditorManagerId: string | undefined
}

export const labelMap: { [key: string]: string } = {
    userName: 'User Name',
    password: 'Password',
    email: 'Email',
    mobile: 'Mobile',
    firstName: 'First Name',
    lastName: 'Last Name',
    mainSpecialityId: 'Main Speciality',
    // secondarySpecialityId: 'Secondary Speciality',
    role: 'Role',
    auditorManagerId: 'Auditor Manager',
}
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
    mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]{10}$/, 'Invalid mobile number'),
    auditorManagerId: Yup.string().when('role', {
        is: 'auditor',
        then: Yup.string().required('Auditor Manager is required'),
        otherwise: Yup.string(),
    }),
    mainSpecialityId: Yup.string().when('role', {
        is: 'auditor',
        then: Yup.string().required('Main speciality is required'),
        otherwise: Yup.string(),
    }),
    // secondarySpecialityId: Yup.string().when('role', {
    //     is: 'auditor',
    //     then: Yup.string().required('Secondary speciality is required'),
    //     otherwise: Yup.string(),
    // }),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    role: Yup.string().required('Role is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    userName: Yup.string().required('User name is required').trim(),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Must be 8 characters or more')
        .matches(/[a-z]+/, 'One lowercase character')
        .matches(/[A-Z]+/, 'One uppercase character')
        .matches(/[@$!%*#?&]+/, 'One special character')
        .matches(/\d+/, 'One number'),
})

const roleData = [
    {
        id: 1,
        value: 'auditor',
        label: 'Auditor',
    },
    {
        id: 2,
        value: 'auditormanager',
        label: 'Auditor Manager',
    },
]
const defaultValues = {
    role: 'auditor',
    userName: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    auditorManagerId: '',
    mainSpecialityId: '',
    // secondarySpecialityId: '',
}
interface UserCreationProps {
    titleLabel: string
}

export function useUserCreation({ titleLabel }: UserCreationProps) {
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const userId = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.id,
    )
    const [open, setOpen] = React.useState(false)
    const [managerList, setManagerList] = React.useState<AuditorlistProps[]>([])
    const [specialityList, setSpecialityList] = React.useState<
        SpecialitylistProps[]
    >([])
    // console.log('specialitylist', specialityList)
    const formik = useFormik<FormValues>({
        initialValues: {
            role: 'auditor',
            userName: '',
            password: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            auditorManagerId: '',
            mainSpecialityId: '',
            // secondarySpecialityId: '',
        },
        validationSchema,
        onSubmit: (values) => {
            submit(values)
        },
    })

    const onClose = () => {
        formik.resetForm({
            values: { ...defaultValues, role: formik.values.role },
        })
        setOpen(false)
    }
    const onOpen = () => {
        formik.resetForm({ values: { ...defaultValues, role: titleLabel } })
        setOpen(true)
    }
    React.useEffect(() => {
        async function fetchData() {
            try {
                const [managersRes, specialityRes]: any = await Promise.all([
                    getManagers(),
                    getSpeciality(),
                ])
                setManagerList(managersRes.users)
                setSpecialityList(specialityRes.data)
            } catch (error) {
                setManagerList([])
                setSpecialityList([])
            }
        }
        fetchData()
    }, [])

    const submit = async (body: any) => {
        loaderSetting(true)

        let requestBody: any

        if (body.role === 'auditormanager') {
            requestBody = {
                role: body.role,
                userName: body.userName,
                password: body.password,
                firstName: body.firstName,
                lastName: body.lastName,
                email: body.email,
                mobile: body.mobile,
                auditorAdminId: userId,
            }
        } else {
            requestBody = { ...body }
        }

        const result = await createUser(requestBody)
        loaderSetting(false)
        if (result?.status) {
            setOpen(false)
            snackbarSetting(true, result?.message, 'success')
        } else {
            snackbarSetting(true, result?.message, 'error')
        }
    }

    return {
        onOpen,
        open,
        formik,
        onClose,
        roleData,
        specialityList,
        managerList,
    }
}
