import { PRODUCTION_DATE, PRODUCTION_END_DATE } from '../../actions/actionType'
import dayjs from 'dayjs'

const date = new Date()
// const date = dayjs()
date.setHours(0, 0, 0, 0)
const initialState = { day: date, endDay: date }
// const initialState = { day: date }

export default function productionDateReducer(
    state = initialState,
    action: any,
) {
    switch (action.type) {
        case PRODUCTION_DATE:
            return {
                ...state,
                day: action.payload,
            }
        case PRODUCTION_END_DATE:
            return {
                ...state,
                endDay: action.payload,
            }
        default:
            return state
    }
}
