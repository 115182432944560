import React, { FC } from 'react'
import styles from './DocumentComponent.module.css'
import TabButton from '../TabButton/TabButton'
import ViewTranscript from '../../Pages/TaskManager/Component/ViewTranscript/ViewTranscript'
import ViewProgressNote from '../../Pages/TaskManager/Component/ViewProgressNote/ViewProgressNote'

const buttons = [
    {
        id: 0,
        label: 'Transcript',
    },
    {
        id: 1,
        label: 'Progress Note',
    },
    {
        id: 2,
        label: 'Document 3',
    },
]

interface DocumentComponentProps {
    appointmentId: any
    scheduleId: any
    encounterId?: any
    encounterData?: any
    aIndex?: any
    addendumData?: any
}

const DocumentComponent: FC<DocumentComponentProps> = ({
    appointmentId,
    scheduleId,
    encounterId,
    encounterData,
    aIndex,
    addendumData,
}) => {
    const [selectedTab, setSelectedTab] = React.useState(0)
    const handleSelect = (id: number) => {
        setSelectedTab(id)
    }
    // console.log('states', encounterData)
    const [progressNote, setProgressNote] = React.useState(
        encounterData.editedProgressNote
            ? encounterData.editedProgressNote
            : encounterData.galen_output['2C'],
    )
    const [addendumPN, setAddendumPN] = React.useState(
        aIndex == null ? null : addendumData.galen_output['2C'],
    )
    // console.log('docpn', aIndex, progressNote, addendumPN)
    return (
        <div className={styles.root}>
            <div className={styles.DocumentComponent}>
                {!encounterData?.codeQ ? (
                    <ViewProgressNote
                        progressNote={progressNote}
                        addendumPN={addendumPN}
                        aIndex={aIndex}
                        styles={styles}
                    />
                ) : (
                    <ViewProgressNote
                        progressNote={encounterData.galen_output['2C']}
                        addendumPN={addendumPN}
                        aIndex={aIndex}
                        styles={styles}
                    />
                )}
            </div>
            {/* <ViewTranscript
                appointmentId={appointmentId}
                scheduleId={scheduleId}
                encounterId={encounterId}
                selectedTab={selectedTab}
            /> */}
        </div>
    )
}

export default DocumentComponent
