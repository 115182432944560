import {
    Button,
    MenuItem,
    Typography,
    Box,
    Tabs,
    Divider,
    Tab,
} from '@mui/material'
import buttonStyles from '../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../Styles/component/Typography.module.css'
import CustomeSelect from '../../../Components/CustomeSelect'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import StatusDropdown from '../../../Components/StatusDropdown'
import { statusTagMenuItem } from '../../../Services/utils/constant'
import styles from '../JobManager.module.css'
import { useAddTask } from '../../../Services/taskManagerService/addTaskService'
import TextFormField from '../../../Components/TextField'
import statuslabel from '../../../Styles/component/StatusLabel.module.css'
import UserComponent from '../../UserComponent/UserComponent'
import ViewTask from './ViewTask'
import ViewAudios from './ViewAudios/ViewAudios'
import EditTask from './EditTask'
import { TabPanel, a11yProps } from '../../../Components/DrawerTab/DrawerTab'
import ReadOnlyField from '../../../Components/ReadOnlyField/ReadOnlyField'
import TaskdrawerHeader from '../../../Components/TaskdrawerHeader/TaskdrawerHeader'
import TextNumericField from '../../../Components/TextNumericField'
import SearchDropdownTextField from '../../../Components/SearchDropdownTextField/SearchDropdownTextField'

type addTaskProps = {
    updatedTaskList: (value: boolean) => void
    handleClosed: () => void
    menuHandleCloseClick: any
}
export default function AddTask({
    updatedTaskList,
    handleClosed,
    menuHandleCloseClick,
}: addTaskProps) {
    const {
        patientName,
        handleSearch,
        handleorgChange,
        showEdit,
        toggleEdit,
        handleTabChange,
        tableValue,
        showView,
        error,
        checkAllField,
        patientList,
        auditorList,
        providerList,
        handleChange,
        handleSubmit,
        handleDropChange,
        handleProvider,
        handleEndChange,
        handleStartChange,
        states,
        handleAppointmentDateChange,
        appointmentDate,
    } = useAddTask({ updatedTaskList })

    return (
        <div>
            {!showView ? (
                <>
                    <TaskdrawerHeader
                        onclick={() => {
                            if (menuHandleCloseClick) {
                                menuHandleCloseClick()
                            }
                        }}
                    />
                    <Divider />
                    <div className={styles.HeaderDiv}>
                        <StatusDropdown
                            name={'status'}
                            handleChange={handleDropChange}
                            value={states.status}
                        >
                            {statusTagMenuItem?.map(
                                ({ key, label, divPrimary, typography }) => (
                                    <MenuItem
                                        sx={{
                                            padding: '11px',
                                        }}
                                        key={key}
                                        value={key}
                                    >
                                        <div
                                            className={
                                                statuslabel[`${divPrimary}`]
                                            }
                                        >
                                            <span
                                                className={
                                                    typoStyles[`${typography}`]
                                                }
                                            >
                                                {label}
                                            </span>
                                        </div>
                                    </MenuItem>
                                ),
                            )}
                        </StatusDropdown>
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                variant="fullWidth"
                                value={tableValue}
                                centered
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    sx={{ textTransform: 'initial' }}
                                    className={typoStyles.typoRobotoMedium1633}
                                    label="Add Details"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    sx={{ textTransform: 'initial' }}
                                    className={typoStyles.typoRobotoMedium1633}
                                    label="Audios"
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={tableValue} index={0}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    height: 'calc(100vh - 201px)',
                                    position: 'relative',
                                }}
                            >
                                <div className={styles.AddTaskRootDiv}>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Task Name'}
                                        </Typography>
                                        <TextFormField
                                            name="taskName"
                                            value={states.taskName}
                                            onChange={handleChange}
                                            error={error.taskNameError}
                                            placeholder="Type here..."
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Auditor'}
                                        </Typography>
                                        <CustomeSelect
                                            value={states?.auditorId}
                                            name={'auditorId'}
                                            handleChange={handleDropChange}
                                        >
                                            {auditorList?.map((item: any) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    <UserComponent
                                                        detail={true}
                                                        auditorName={
                                                            item.firstName +
                                                            ' ' +
                                                            item.lastName
                                                        }
                                                        auditorId={item.id}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </CustomeSelect>
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Organization TIN'}
                                        </Typography>
                                        <TextNumericField
                                            name="orgTid"
                                            value={states.orgTid}
                                            onChange={handleorgChange}
                                            error={error.orgTidError}
                                            placeholder="Type here..."
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Appointment Date'}
                                        </Typography>
                                        <CustomDatePicker
                                            value={appointmentDate}
                                            onChange={
                                                handleAppointmentDateChange
                                            }
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Provider Name'}
                                        </Typography>
                                        <CustomeSelect
                                            value={states?.providerId}
                                            name={'providerId'}
                                            handleChange={handleProvider}
                                        >
                                            {providerList?.map((item: any) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    <UserComponent
                                                        detail={true}
                                                        auditorName={
                                                            item.firstName +
                                                            ' ' +
                                                            item.lastName
                                                        }
                                                        auditorId={item.id}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </CustomeSelect>
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Speciality'}
                                        </Typography>
                                        <TextFormField
                                            name="speciality"
                                            placeholder="Type here..."
                                            error={error.specialityError}
                                            value={states.speciality}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Patient Name'}
                                        </Typography>
                                        <div>
                                            <SearchDropdownTextField
                                                onChange={handleSearch}
                                                options={patientList}
                                                searchQuery={patientName}
                                            />
                                        </div>
                                    </div>
                                    <ReadOnlyField
                                        name={'Location'}
                                        value={states.location}
                                    />
                                    <ReadOnlyField
                                        name={'MRN'}
                                        value={states?.mrn}
                                    />
                                    <ReadOnlyField
                                        name={'Patient Account'}
                                        value={states?.patientAccount}
                                    />
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Patient DOB'}
                                        </Typography>
                                        <CustomDatePicker
                                            value={states?.patientdob}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Patient Gender'}
                                        </Typography>
                                        <CustomeSelect
                                            value={states?.patientGender}
                                            name={'patientGender'}
                                        >
                                            <MenuItem key={'M'} value={'M'}>
                                                <Typography
                                                    className={
                                                        typoStyles.typoRobotoRegoularprimary5714
                                                    }
                                                >
                                                    {'Male'}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem key={'F'} value={'F'}>
                                                <Typography
                                                    className={
                                                        typoStyles.typoRobotoRegoularprimary5714
                                                    }
                                                >
                                                    {'Female'}
                                                </Typography>
                                            </MenuItem>
                                        </CustomeSelect>
                                    </div>
                                    <ReadOnlyField
                                        name={'Encounter Collection'}
                                        value={
                                            states?.encounter?.length > 0
                                                ? states?.encounter?.length
                                                : ''
                                        }
                                    />
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Start Date'}
                                        </Typography>
                                        <CustomDatePicker
                                            value={states?.startDate}
                                            onChange={handleStartChange}
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'End Date'}
                                        </Typography>
                                        <CustomDatePicker
                                            value={states?.endDate}
                                            onChange={handleEndChange}
                                        />
                                    </div>
                                    <ReadOnlyField
                                        name={'Appointment Type'}
                                        value={states?.type}
                                    />

                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Comment'}
                                        </Typography>
                                        <TextFormField
                                            name="comment"
                                            value={states.comment}
                                            onChange={handleChange}
                                            placeholder="Type here..."
                                        />
                                    </div>
                                    <div className={styles.footerDialogDiv}>
                                        <Button
                                            className={
                                                buttonStyles.cancelbutton
                                            }
                                            onClick={handleClosed}
                                        >
                                            <Typography
                                                className={
                                                    typoStyles.typoRobotoRegoularprimary5914
                                                }
                                            >
                                                {'Cancel'}
                                            </Typography>
                                        </Button>
                                        <Button
                                            disabled={checkAllField()}
                                            className={buttonStyles.yesbutton}
                                            onClick={() => handleSubmit()}
                                        >
                                            <Typography
                                                className={
                                                    typoStyles.typoRobotoMediumprimary214
                                                }
                                            >
                                                {'Save'}
                                            </Typography>
                                        </Button>
                                    </div>
                                    <div style={{ height: '50px' }}></div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={tableValue} index={1}>
                            <ViewAudios
                                appointmentId={states?.appointmentId}
                                scheduleId={states?.scheduleId}
                            />
                        </TabPanel>
                    </Box>
                </>
            ) : !showEdit ? (
                <ViewTask
                    openEdit={toggleEdit}
                    task={states}
                    showEditbtn={true}
                    menuHandleCloseClick={menuHandleCloseClick}
                    updatedTaskList={updatedTaskList}
                />
            ) : (
                <EditTask
                    task={states}
                    menuHandleCloseClick={menuHandleCloseClick}
                    handleClosed={toggleEdit}
                    updatedTaskList={updatedTaskList}
                />
            )}
        </div>
    )
}
