import React, { useState } from 'react'
import { Table, TableContainer } from '@mui/material'

import TableHeader from '../TableHeader'
import TableBodyField from '../../Pages/TaskManager/Component/TableBodyField/TableBodyField'
import RightSideDrawer from '../../Pages/TaskManager/Component/RightSideDrawer'
import TaskNoRecord from '../TaskNoRecord/TaskNoRecord'
import { useTableService } from '../../Services/taskManagerService/tableService'
import { useTaskmanager } from '../../Services/taskManagerService/taskManagerService'
import { UnreadCountProvider } from '../Unreadcount/UnreadMessageCount'
import ViewTask from '../../Pages/TaskManager/Component/ViewTask'
import EditTask from '../../Pages/TaskManager/Component/EditTask'
import ReassignTask from '../../Pages/TaskManager/Component/ReassignTask/ReassignTask'
import TaskStatusChange from '../../Pages/TaskManager/Component/TaskStatusChange/TaskStatusChange'
import ChatComponent from '../../Pages/ChatComponent/ChatComponent'
import CustomDialog from '../CustomDialog'
import MarkClaimAlert from '../../Pages/TaskManager/Component/MarkClaimAlert/MarkClaimAlert'
import SuccessAlert from '../SuccessAlert/SuccessAlert'
import AddTask from '../../Pages/TaskManager/Component/AddTask'
import styles from './AttentionJobsTable.module.css'
function AttentionJobsTable() {
    const {
        sortingObj,
        bubblechartData,
        barchartDataCount,
        barchartData,
        chartFilter,
        filterMenuChange,
        filterOnChange,
        filterSearchByField,
        searchByName,
        auditors,
        updatedTaskList,
        closeTaskDrawer,
        task,
        addendumIndex,
        // aTask,
        enableModel,
        name,
        openFilter,
        filterFlag,
        pagination,
        setPagination,
        taskList,
        filteredValue,
        filterdHeader,
        handleRequestSort,
        openDrawer,
        openModel,
        modalTranscriptClose,
        transcriptmodal,
        openTranscriptModel,
        openFillablePdfModel,
        fillablePdfmodal,
        openDocuemt3PdfModel,
        encounterData,
        addendumEncounterData,
        statusCount,
        filterButton,
        setFilterButton,
    } = useTaskmanager()
    const { selectField } = useTableService()
    const [chatnavigation, setFlagShowMsg] = useState<any>(null)
    // console.log('tasklist2', taskList)

    return (
        <div>
            <TableContainer sx={{ height: '70vh' }}>
                <Table
                    stickyHeader
                    // sx={{ width: '10dvw', borderBlock: 'black' }}
                    aria-label="simple table"
                >
                    <TableHeader
                        sortingObj={sortingObj}
                        handleRequestSort={handleRequestSort}
                        from={'attention'}
                    />
                    <TableBodyField
                        from={'attention'}
                        filteredTask={taskList}
                        // addendumData={addendumEncounterData}
                        openDrawer={openDrawer}
                        modalOpen={openModel}
                        openTranscriptModel={openTranscriptModel}
                        setFlagShowMsg={setFlagShowMsg}
                        openFillablePdfModel={openFillablePdfModel}
                        openDocuemt3PdfModel={openDocuemt3PdfModel}
                    />
                </Table>
                <TaskNoRecord record={taskList} />
            </TableContainer>
            <RightSideDrawer
                showShodow={true}
                onClose={closeTaskDrawer}
                openDrawer={
                    enableModel === 0 ||
                    enableModel === 1 ||
                    enableModel === 2 ||
                    enableModel === 3 ||
                    enableModel === 4 ||
                    enableModel === 5
                        ? true
                        : false
                }
            >
                {enableModel === 0 && (
                    <UnreadCountProvider>
                        <ViewTask
                            aIndex={addendumIndex}
                            task={task}
                            encounterData={encounterData}
                            addendumData={addendumEncounterData}
                            menuHandleCloseClick={closeTaskDrawer}
                            updatedTaskList={updatedTaskList}
                            openEdit={() => {
                                openDrawer(1, task)
                            }}
                            showEditbtn={true}
                        />
                    </UnreadCountProvider>
                )}
                {enableModel === 1 && (
                    <EditTask
                        task={task}
                        menuHandleCloseClick={closeTaskDrawer}
                        updatedTaskList={updatedTaskList}
                        handleClosed={() => {
                            openDrawer(0, task)
                        }}
                    />
                )}
                {enableModel === 2 && (
                    <ReassignTask
                        task={task}
                        menuHandleCloseClick={closeTaskDrawer}
                        updatedTaskList={updatedTaskList}
                        auditorList={auditors}
                    />
                )}
                {enableModel === 3 && (
                    <TaskStatusChange
                        task={task}
                        menuHandleCloseClick={closeTaskDrawer}
                        updatedTaskList={updatedTaskList}
                    />
                )}
                {enableModel === 4 && (
                    <AddTask
                        menuHandleCloseClick={closeTaskDrawer}
                        updatedTaskList={updatedTaskList}
                        handleClosed={closeTaskDrawer}
                    />
                )}
                {enableModel === 5 && (
                    <ChatComponent
                        taskName={task?.taskName}
                        menuHandleCloseClick={closeTaskDrawer}
                        patientName={task?.patientName}
                        mrn={task?.mrn}
                        taskId={task.id}
                        providerId={task?.providerId}
                        auditorId={task.auditorId}
                        managerId={task.createdBy}
                        appointmentId={task.appointmentId}
                        chatnavigation={chatnavigation}
                        setFlagShowMsg={setFlagShowMsg}
                    />
                )}
            </RightSideDrawer>
            <CustomDialog
                open={enableModel === 6 || enableModel === 7 ? true : false}
                handleClick={closeTaskDrawer}
            >
                {enableModel === 6 && (
                    <MarkClaimAlert
                        task={task}
                        menuHandleCloseClick={closeTaskDrawer}
                        updatedTaskList={updatedTaskList}
                    />
                )}
                {enableModel === 7 && (
                    <SuccessAlert
                        conFirmOnClick={closeTaskDrawer}
                        primaryLable={
                            'Claim is marked as submitted successfully'
                        }
                    />
                )}
            </CustomDialog>
        </div>
    )
}

export default AttentionJobsTable
