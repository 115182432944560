import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { Grid, MenuItem } from '@mui/material'
import UserComponent from '../../../UserComponent/UserComponent'
import styles from './AuditorEditSetting.module.css'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import buttonStyles from '../../../../Styles/component/ButtonStyle.module.css'
import CustomeSelect from '../../../../Components/CustomeSelect'
import { useAuditorSetting } from '../../../../Services/auditorManagerService/auditorManagerSetting'

interface AuditorEditSettingProps {
    open: boolean
    onClose: (value: boolean, data: any) => void
    auditorData: any
    updateAuditorList?: any
}

export function AuditorEditSetting(props: AuditorEditSettingProps) {
    const { onClose, open, auditorData, updateAuditorList } = props

    const {
        states,
        specialityList,
        submitSetting,
        handleMainSpeciality,
        handleSpeSpeciality,
        handleProvider,
        handleManager,
        providerList,
        managerList,
    } = useAuditorSetting({ onClose, auditorData, updateAuditorList })

    return (
        <Dialog
            maxWidth={'xs'}
            onClose={() => onClose(false, null)}
            open={open}
        >
            <Grid className={styles.dialog_root_div} container spacing={2}>
                <Grid item xs={12}>
                    <UserComponent
                        auditorId={auditorData?.auditorId}
                        auditorName={auditorData?.auditorName}
                    />
                </Grid>
                <Grid item xs={6} className={styles.spec_div}>
                    <span className={typoStyles.typoRobotoBoldprimary71419}>
                        {'Main speciality'}
                    </span>
                    <CustomeSelect
                        value={states?.mainSpecialityId}
                        name={'mainSpecialityId'}
                        handleChange={handleMainSpeciality}
                    >
                        {specialityList
                            ?.filter(
                                (item: any) =>
                                    item.id !== states?.secondarySpecialityId,
                            )
                            ?.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    <span
                                        className={`${typoStyles.typoRobotoRegularprimary71824} ${styles.textOverflow}`}
                                    >
                                        {item.desc}
                                    </span>
                                </MenuItem>
                            ))}
                    </CustomeSelect>
                </Grid>
                <Grid item xs={6} className={styles.spec_div}>
                    <span className={typoStyles.typoRobotoBoldprimary71419}>
                        {'Secondary speciality'}
                    </span>
                    <CustomeSelect
                        value={states?.secondarySpecialityId}
                        name={'secondarySpecialityId'}
                        handleChange={handleSpeSpeciality}
                    >
                        {specialityList
                            ?.filter(
                                (item: any) =>
                                    item.id !== states?.mainSpecialityId,
                            )
                            ?.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    <span
                                        className={`${typoStyles.typoRobotoRegularprimary71824} ${styles.textOverflow}`}
                                    >
                                        {item.desc}
                                    </span>{' '}
                                </MenuItem>
                            ))}
                    </CustomeSelect>
                </Grid>
                <Grid item xs={12} className={styles.provider_div}>
                    <span className={typoStyles.typoRobotoBoldprimary71419}>
                        {'Provider'}
                    </span>
                    <CustomeSelect
                        value={states?.providerId}
                        name={'providerId'}
                        handleChange={handleProvider}
                    >
                        {providerList?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                <UserComponent
                                    detail={true}
                                    auditorName={
                                        item.firstName + ' ' + item.lastName
                                    }
                                    auditorId={item.id}
                                />
                            </MenuItem>
                        ))}
                    </CustomeSelect>
                </Grid>
                <Grid item xs={12} className={styles.manager_div}>
                    <span className={typoStyles.typoRobotoBoldprimary71419}>
                        {'Manager'}
                    </span>
                    <CustomeSelect
                        value={states?.managerId}
                        name={'managerId'}
                        handleChange={handleManager}
                    >
                        {managerList?.map((item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                                <UserComponent
                                    detail={true}
                                    auditorName={
                                        item.firstName + ' ' + item.lastName
                                    }
                                    auditorId={item.id}
                                />
                            </MenuItem>
                        ))}
                    </CustomeSelect>
                </Grid>
                <Grid item xs={12} className={styles.footer}>
                    <Button
                        className={buttonStyles.cancelbutton}
                        onClick={() => onClose(false, null)}
                    >
                        <span
                            className={typoStyles.typoRobotoRegoularprimary5914}
                        >
                            {'Cancel'}
                        </span>
                    </Button>
                    <Button
                        disabled={
                            states?.mainSpecialityId !== '' &&
                            states?.auditorId !== '' &&
                            states?.managerId !== '' &&
                            states?.providerId !== '' &&
                            states?.secondarySpecialityId !== ''
                                ? false
                                : true
                        }
                        className={buttonStyles.yesbutton}
                        onClick={() => submitSetting()}
                    >
                        <span className={typoStyles.typoRobotoMediumprimary214}>
                            {'Save'}
                        </span>
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}
