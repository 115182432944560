import React, { useState } from 'react'
import IndividualAuditor from '../../../../Components/IndividualAuditor/IndividualAuditor'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, IconButton, MenuItem } from '@mui/material'
import Menu, { MenuProps } from '@mui/material/Menu'
import { styled, alpha } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import EditIcon from '@mui/icons-material/Edit'
import ReassignTask from '../../../TaskManager/Component/ReassignTask/ReassignTask'
import { useTaskmanager } from '../../../../Services/taskManagerService/taskManagerService'

function TATMeterAuditorIndividual() {
    const navigate = useNavigate()
    const location = useLocation()
    const [checkTrigger, setCheckTrigger] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const {
        sortingObj,
        bubblechartData,
        barchartDataCount,
        barchartData,
        chartFilter,
        filterMenuChange,
        filterOnChange,
        filterSearchByField,
        searchByName,
        auditors,
        updatedTaskList,
        closeTaskDrawer,
        task,
        addendumIndex,
        // aTask,
        enableModel,
        name,
        openFilter,
        filterFlag,
        pagination,
        setPagination,
        taskList,
        filteredValue,
        filterdHeader,
        handleRequestSort,
        openDrawer,
        openModel,
        modalTranscriptClose,
        transcriptmodal,
        openTranscriptModel,
        openFillablePdfModel,
        fillablePdfmodal,
        openDocuemt3PdfModel,
        encounterData,
        addendumEncounterData,
        statusCount,
        filterButton,
        setFilterButton,
    } = useTaskmanager()
    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color: 'rgb(55, 65, 81)',
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[300],
            }),
        },
    }))

    return (
        <div style={{ margin: '5px 5px 20px 5px' }}>
            <div
                style={{
                    paddingLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <IconButton
                    sx={{ marginRight: 5 }}
                    aria-label="back a page"
                    onClick={() => navigate(-1)}
                >
                    <ArrowBackIcon />
                </IconButton>
                <div>
                    Job List For {location.state.firstName}{' '}
                    {location.state.lastName}
                </div>
                {checkTrigger ? (
                    <div>
                        <Button
                            id="demo-customized-button"
                            aria-controls={
                                open ? 'demo-customized-menu' : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            Reassign Job(s)
                        </Button>

                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose} disableRipple>
                                {/* <EditIcon />
                                Edit */}
                                <ReassignTask
                                    task={task}
                                    menuHandleCloseClick={closeTaskDrawer}
                                    updatedTaskList={updatedTaskList}
                                    auditorList={auditors}
                                />
                            </MenuItem>
                        </StyledMenu>
                    </div>
                ) : null}
            </div>
            <IndividualAuditor
                id={location.state.id}
                trigger={setCheckTrigger}
            />
        </div>
    )
}

export default TATMeterAuditorIndividual
