import { FC, useRef, useState } from 'react'
import {
    initial2CValuesType,
    reviewOfSystemType,
    physicalExamType,
    rosKeys,
    peKeys,
    review,
    PE,
} from '../../../Coding/CodingInterfaces'
import _ from 'lodash'
import Diff from 'react-stylable-diff'
import styles2 from './ViewProgressNote.module.css'
import Divider from '@mui/material/Divider'

interface ViewProgressNoteProps {
    aIndex?: any
    addendumPN?: any
    progressNote?: any
    cleanPN?: any
    styles?: any
}
const ViewProgressNote: FC<ViewProgressNoteProps> = (
    props: ViewProgressNoteProps,
) => {
    // const { transcripts, rootref, onScroll, onSelectTranscript, open, onClose, pdfUrl } = useViewTranscript(props);
    // const [numPages, setNumPages] = useState<number | null>(null);

    // const pdfContainerRef = useRef<HTMLDivElement>(null);

    // function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    //   setNumPages(numPages);
    // }

    // function handleContextMenu(event: React.MouseEvent<HTMLDivElement>) {
    //   event.preventDefault(); // Prevent default context menu behavior
    // }
    //   const downloadFile = () => {
    //     if (pdfUrl) {
    //         const a = document.createElement('a');
    //         a.href = pdfUrl;
    //         a.download = transcript.filePathPdf_ChartNote?.split('/')[transcript.filePathPdf_ChartNote?.split('/').length - 1];
    //         a.click();
    //     }
    // };

    return {
        ...(props.aIndex == null ? (
            <div>
                {props.cleanPN
                    ? Object.keys(props.cleanPN).map(
                          (pNotesKeys: any, index: number) => {
                              if (
                                  pNotesKeys == 'REVIEW OF SYSTEMS' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'REVIEW OF SYSTEMS' as keyof initial2CValuesType
                                          ],
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>REVIEW OF SYSTEMS:</b>
                                              {rosKeys?.map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote[
                                                              'REVIEW OF SYSTEMS'
                                                          ]![
                                                              keys as keyof reviewOfSystemType
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote[
                                                                                  'REVIEW OF SYSTEMS'
                                                                              ]![
                                                                                  keys as keyof reviewOfSystemType
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (
                                  pNotesKeys == 'PHYSICAL EXAM' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'PHYSICAL EXAM' as keyof initial2CValuesType
                                          ],
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>PHYSICAL EXAM:</b>
                                              {peKeys.map((peKey, index) => {
                                                  if (
                                                      props.progressNote[
                                                          'PHYSICAL EXAM'
                                                      ]![
                                                          peKey as keyof physicalExamType
                                                      ]
                                                  ) {
                                                      return (
                                                          <div
                                                              key={
                                                                  peKey + index
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      props
                                                                          .styles
                                                                          ?.PNKeys2
                                                                  }
                                                              >
                                                                  {peKey}:
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNData
                                                                      }
                                                                  >
                                                                      {
                                                                          props
                                                                              .progressNote[
                                                                              'PHYSICAL EXAM'
                                                                          ]![
                                                                              peKey as keyof physicalExamType
                                                                          ]
                                                                      }
                                                                  </div>
                                                                  <Divider
                                                                      variant="middle"
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.divider
                                                                      }
                                                                  />
                                                              </div>
                                                          </div>
                                                      )
                                                  }
                                              })}
                                          </div>
                                      )
                              } else if (
                                  props.progressNote[
                                      pNotesKeys as keyof typeof props.progressNote
                                  ] != null
                              ) {
                                  if (
                                      props.progressNote[
                                          pNotesKeys as keyof typeof props.progressNote
                                      ]?.length > 0
                                  )
                                      return (
                                          <>
                                              <div
                                                  key={pNotesKeys + index}
                                                  className={
                                                      props.styles?.ReportKeys
                                                  }
                                              >
                                                  {pNotesKeys}:
                                                  <div
                                                      className={
                                                          props.styles?.PNData
                                                      }
                                                  >
                                                      {
                                                          props.progressNote[
                                                              pNotesKeys as keyof typeof props.progressNote
                                                          ]
                                                      }
                                                  </div>
                                                  <Divider
                                                      variant="middle"
                                                      className={
                                                          props.styles?.divider
                                                      }
                                                  />
                                              </div>
                                          </>
                                      )
                              }
                          },
                      )
                    : Object.keys(props.progressNote).map(
                          (pNotesKeys: any, index: number) => {
                              if (pNotesKeys == 'REVIEW OF SYSTEMS') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'REVIEW OF SYSTEMS' as keyof initial2CValuesType
                                          ],
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>REVIEW OF SYSTEMS:</b>
                                              {rosKeys.map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote[
                                                              'REVIEW OF SYSTEMS'
                                                          ]![
                                                              keys as keyof reviewOfSystemType
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote[
                                                                                  'REVIEW OF SYSTEMS'
                                                                              ]![
                                                                                  keys as keyof reviewOfSystemType
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      {index <
                                                                      rosKeys.length ? (
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      ) : (
                                                                          <></>
                                                                      )}
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'PHYSICAL EXAM') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'PHYSICAL EXAM' as keyof initial2CValuesType
                                          ],
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>PHYSICAL EXAM:</b>
                                              {peKeys.map((peKey, index) => {
                                                  if (
                                                      props.progressNote[
                                                          'PHYSICAL EXAM'
                                                      ]![
                                                          peKey as keyof physicalExamType
                                                      ]
                                                  ) {
                                                      return (
                                                          <div
                                                              key={
                                                                  peKey + index
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      props
                                                                          .styles
                                                                          ?.PNKeys2
                                                                  }
                                                              >
                                                                  {peKey}:
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNData
                                                                      }
                                                                  >
                                                                      {
                                                                          props
                                                                              .progressNote[
                                                                              'PHYSICAL EXAM'
                                                                          ]![
                                                                              peKey as keyof physicalExamType
                                                                          ]
                                                                      }
                                                                  </div>
                                                                  <Divider
                                                                      variant="middle"
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.divider
                                                                      }
                                                                  />
                                                              </div>
                                                          </div>
                                                      )
                                                  }
                                              })}
                                          </div>
                                      )
                              } else if (
                                  props.progressNote[
                                      pNotesKeys as keyof typeof props.progressNote
                                  ] != null
                              ) {
                                  if (
                                      props.progressNote[
                                          pNotesKeys as keyof typeof props.progressNote
                                      ]?.length > 0
                                  )
                                      return (
                                          <>
                                              <div
                                                  key={pNotesKeys + index}
                                                  className={
                                                      props.styles?.ReportKeys
                                                  }
                                              >
                                                  {pNotesKeys}:
                                                  <div
                                                      className={
                                                          props.styles?.PNData
                                                      }
                                                  >
                                                      {
                                                          props.progressNote[
                                                              pNotesKeys as keyof typeof props.progressNote
                                                          ]
                                                      }
                                                  </div>
                                                  <Divider
                                                      variant="middle"
                                                      className={
                                                          props.styles?.divider
                                                      }
                                                  />
                                              </div>
                                          </>
                                      )
                              }
                          },
                      )}
            </div>
        ) : (
            <div>
                {props.cleanPN
                    ? Object.keys(props.cleanPN).map(
                          (pNotesKeys: any, index: number) => {
                              if (pNotesKeys == 'REVIEW OF SYSTEMS') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'REVIEW OF SYSTEMS' as keyof initial2CValuesType
                                          ],
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>REVIEW OF SYSTEMS:</b>
                                              {rosKeys.map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN[
                                                              'REVIEW OF SYSTEMS'
                                                          ]![
                                                              keys as keyof reviewOfSystemType
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {/* <div
                                                                              style={{
                                                                                  backgroundColor:
                                                                                      '#fff7b0',
                                                                              }}
                                                                          >
                                                                              {
                                                                                  props
                                                                                      .progressNote[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                          </div>
                                                                          <div>
                                                                              {
                                                                                  props
                                                                                      .addendumPN[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                          </div> */}
                                                                          <Diff
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'PHYSICAL EXAM') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN[
                                              'PHYSICAL EXAM' as keyof initial2CValuesType
                                          ],
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>PHYSICAL EXAM:</b>
                                              {peKeys.map((peKey, index) => {
                                                  if (
                                                      props.addendumPN[
                                                          'PHYSICAL EXAM'
                                                      ]![
                                                          peKey as keyof physicalExamType
                                                      ] == null ||
                                                      props.addendumPN[
                                                          'PHYSICAL EXAM'
                                                      ]![
                                                          peKey as keyof physicalExamType
                                                      ] != ''
                                                  ) {
                                                      return (
                                                          <div
                                                              key={
                                                                  peKey + index
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      props
                                                                          .styles
                                                                          ?.PNKeys2
                                                                  }
                                                              >
                                                                  {peKey}:
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNData
                                                                      }
                                                                  >
                                                                      {/* <div
                                                                          style={{
                                                                              backgroundColor:
                                                                                  '#fff7b0',
                                                                          }}
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <div>
                                                                          {
                                                                              props
                                                                                  .addendumPN[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                      </div> */}
                                                                      <Diff
                                                                          className={
                                                                              styles2.Difference
                                                                          }
                                                                          inputA={
                                                                              props
                                                                                  .progressNote[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                          inputB={
                                                                              props
                                                                                  .addendumPN[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                          type="words"
                                                                      />
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      )
                                                  }
                                              })}
                                          </div>
                                      )
                              } else if (
                                  //   props.addendumPN[
                                  //       pNotesKeys as keyof typeof props.addendumPN
                                  //   ] !== '' ||
                                  props.addendumPN[
                                      pNotesKeys as keyof typeof props.addendumPN
                                  ] != null
                              ) {
                                  //   console.log(
                                  //       'propslog',
                                  //       pNotesKeys,
                                  //       props.addendumPN[
                                  //           pNotesKeys as keyof typeof props.addendumPN
                                  //       ] != null,
                                  //       props.addendumPN[
                                  //           pNotesKeys as keyof typeof props.addendumPN
                                  //       ] != '' ||
                                  //           props.addendumPN[
                                  //               pNotesKeys as keyof typeof props.addendumPN
                                  //           ] != null,
                                  //   )
                                  return (
                                      <div
                                          key={pNotesKeys + index}
                                          className={props.styles?.ReportKeys}
                                      >
                                          {pNotesKeys}:
                                          <div className={props.styles?.PNData}>
                                              {/* <div
                                                  style={{
                                                      backgroundColor:
                                                          '#fff7b0',
                                                  }}
                                              >
                                                  {
                                                      props.progressNote[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]
                                                  }
                                              </div>
                                              <div>
                                                  {
                                                      props.addendumPN[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]
                                                  }
                                              </div> */}
                                              <Diff
                                                  className={styles2.Difference}
                                                  inputA={
                                                      props.progressNote[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]
                                                  }
                                                  inputB={
                                                      props.addendumPN[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]
                                                  }
                                                  type="words"
                                              />
                                              <Divider
                                                  variant="middle"
                                                  className={
                                                      props.styles?.divider
                                                  }
                                              />
                                          </div>
                                      </div>
                                  )
                              }
                          },
                      )
                    : Object.keys(props.progressNote).map(
                          (pNotesKeys: any, index: number) => {
                              if (pNotesKeys == 'REVIEW OF SYSTEMS') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'REVIEW OF SYSTEMS' as keyof initial2CValuesType
                                          ],
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>REVIEW OF SYSTEMS:</b>
                                              {rosKeys.map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN?.[
                                                              'REVIEW OF SYSTEMS'
                                                          ]![
                                                              keys as keyof reviewOfSystemType
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          {/* <div
                                                                              style={{
                                                                                  backgroundColor:
                                                                                      '#fff7b0',
                                                                              }}
                                                                          >
                                                                              {
                                                                                  props
                                                                                      .progressNote[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                          </div>
                                                                          <div>
                                                                              {
                                                                                  props
                                                                                      .addendumPN[
                                                                                      'REVIEW OF SYSTEMS'
                                                                                  ]![
                                                                                      keys as keyof reviewOfSystemType
                                                                                  ]
                                                                              }
                                                                          </div> */}
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'PHYSICAL EXAM') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN?.[
                                              'PHYSICAL EXAM' as keyof initial2CValuesType
                                          ],
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>PHYSICAL EXAM:</b>
                                              {peKeys.map((peKey, index) => {
                                                  if (
                                                      props.addendumPN?.[
                                                          'PHYSICAL EXAM'
                                                      ]![
                                                          peKey as keyof physicalExamType
                                                      ]
                                                  ) {
                                                      return (
                                                          <div
                                                              key={
                                                                  peKey + index
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      props
                                                                          .styles
                                                                          ?.PNKeys2
                                                                  }
                                                              >
                                                                  {peKey}:
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNData
                                                                      }
                                                                  >
                                                                      <Diff
                                                                          className={
                                                                              styles2.Difference
                                                                          }
                                                                          inputA={
                                                                              props
                                                                                  .progressNote[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                          inputB={
                                                                              //   props
                                                                              //       .progressNote[
                                                                              //       'PHYSICAL EXAM'
                                                                              //   ]![
                                                                              //       peKey as keyof physicalExamType
                                                                              //   ] +
                                                                              //   ' ' +
                                                                              props
                                                                                  .addendumPN?.[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                          type="words"
                                                                      />
                                                                      {/* <div
                                                                          style={{
                                                                              backgroundColor:
                                                                                  '#fff7b0',
                                                                          }}
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <div
                                                                      //   style={{
                                                                      //       backgroundColor:
                                                                      //           '#fff7b0',
                                                                      //   }}
                                                                      >
                                                                          {props
                                                                              .progressNote[
                                                                              'PHYSICAL EXAM'
                                                                          ]![
                                                                              peKey as keyof physicalExamType
                                                                          ] +
                                                                              ' ' +
                                                                              props
                                                                                  .addendumPN?.[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]}
                                                                      </div> */}
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      )
                                                  }
                                              })}
                                          </div>
                                      )
                              } else if (
                                  //   props.addendumPN[
                                  //       pNotesKeys as keyof typeof props.addendumPN
                                  //   ] !== '' ||
                                  props.addendumPN?.[
                                      pNotesKeys as keyof typeof props.addendumPN
                                  ] != null
                              ) {
                                  //   console.log(
                                  //       'propslog',
                                  //       pNotesKeys,
                                  //       props.addendumPN[
                                  //           pNotesKeys as keyof typeof props.addendumPN
                                  //       ] != null,
                                  //       props.addendumPN[
                                  //           pNotesKeys as keyof typeof props.addendumPN
                                  //       ] != '' ||
                                  //           props.addendumPN[
                                  //               pNotesKeys as keyof typeof props.addendumPN
                                  //           ] != null,
                                  //   )
                                  return (
                                      <div
                                          key={pNotesKeys + index}
                                          className={props.styles?.ReportKeys}
                                      >
                                          {pNotesKeys}:
                                          <div className={props.styles?.PNData}>
                                              {/* <div
                                                  style={{
                                                      backgroundColor:
                                                          '#fff7b0',
                                                  }}
                                              >
                                                  {
                                                      props.progressNote[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]
                                                  }
                                              </div>
                                              <div
                                              //   style={{
                                              //       backgroundColor:
                                              //           '#fff7b0',
                                              //   }}
                                              >
                                                  {props.progressNote[
                                                      pNotesKeys as keyof typeof props.progressNote
                                                  ] +
                                                      ' ' +
                                                      props.addendumPN[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]}
                                              </div> */}
                                              <Diff
                                                  className={styles2.Difference}
                                                  inputA={
                                                      props.progressNote[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]
                                                  }
                                                  inputB={
                                                      //   props.progressNote[
                                                      //       pNotesKeys as keyof typeof props.progressNote
                                                      //   ] +
                                                      //   ' ' +
                                                      props.addendumPN[
                                                          pNotesKeys as keyof typeof props.progressNote
                                                      ]
                                                  }
                                                  type="words"
                                              />
                                              <Divider
                                                  variant="middle"
                                                  className={
                                                      props.styles?.divider
                                                  }
                                              />
                                          </div>
                                      </div>
                                  )
                              }
                          },
                      )}
            </div>
        )),
    }
}

export default ViewProgressNote
