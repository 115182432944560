import React, { useState } from 'react'
import styles from './ManagerUserManagement.module.css'
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
} from '@mui/material'
import moment from 'moment'
import { Search } from '@mui/icons-material'
import {
    SearchIconWrapper,
    StatusTag,
} from '../../TaskManager/JobManagerMUIStyles'
import { useNavigate } from 'react-router-dom'
import TableThreeDotMenu from '../../../Components/TableThreeDotMenu/TableThreeDotMenu'
import typoStyles from '../../../Styles/component/Typography.module.css'
import MessageIcon from '../../../Assests/Icons/MessageIcon.png'
import TableHeader from '../../../Components/TableHeader'
import TaskNoRecord from '../../../Components/TaskNoRecord/TaskNoRecord'
import { useTaskmanager } from '../../../Services/taskManagerService/taskManagerService'
import { useTableService } from '../../../Services/taskManagerService/tableService'
import AuditorCreation from '../../User/UserCreation/AuditorCreation'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useAuditorManager } from '../../../Services/auditorManagerService/auditorManagerService'
import { AuditorEditSetting } from '../../Auditors/Component/AuditorEditSetting/AuditorEditSetting'
import TablePagination from '../../../Components/TablePagination/TablePagination'

function ManagerUserManagement() {
    const { sortingObj, auditors, handleRequestSort } = useTaskmanager()

    const { selectField } = useTableService({
        from: 'ManagerUM',
    })

    const {
        updateAuditorList,
        auditorList,
        pagination,
        setPagination,
        searchByName,
        // name,
        // openDrawer,
        taskList,
        enbaleModel,
        listPagination,
        listInnerRef,
        onScroll,
        selectedTaskItem,
        openViewDrawer,
        task,
        closeDrawer,
        editDrawer,
        updatedTaskList,
        enbaleTaskModel,
        closeTaskDrawer,
        handleSetting,
        openSetting,
        auditorData,
    } = useAuditorManager()

    return auditors ? (
        <div className={styles.UMContainer}>
            <div className={styles.UMHeader}>
                <div>Auditor List</div>
                <div className={styles.UMAddSearch}>
                    <div>
                        <AuditorCreation titleLabel="auditor" />
                        {/* <Button variant="contained">Add User</Button> */}
                    </div>
                    <div>
                        <TextField
                            size="small"
                            id="input-with-icon-textfield"
                            InputProps={{
                                placeholder: 'Search',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                    </div>
                </div>
            </div>
            <div>
                <TableContainer sx={{ height: '75dvh' }}>
                    <Table
                        stickyHeader
                        // sx={{ width: '10dvw', borderBlock: 'black' }}
                        aria-label="simple table"
                    >
                        <TableHeader
                            sortingObj={sortingObj}
                            handleRequestSort={handleRequestSort}
                            from={'ManagerUM'}
                        />
                        <TableBody>
                            {Object.values(auditors)?.map(
                                (data: any, index: number) => {
                                    return (
                                        <TableRow key={index + 'testdata'}>
                                            {Object.values(selectField).map(
                                                (item: any, index2: number) => {
                                                    if (index2 == 0) {
                                                        return (
                                                            <TableCell
                                                                key={
                                                                    'check' +
                                                                    index
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    border: '0.5px solid #A0A0A0',
                                                                    whiteSpace:
                                                                        'wrap',
                                                                }}
                                                            >
                                                                {/* <Checkbox
                                                            checked={_.includes(
                                                                checkedList,
                                                                data.id,
                                                            )}
                                                            onChange={() =>
                                                                handleCheck(
                                                                    data,
                                                                )
                                                            }
                                                        /> */}
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return (
                                                            <BodyField
                                                                key={
                                                                    index +
                                                                    item.key +
                                                                    ':original'
                                                                }
                                                                // openDrawer={
                                                                //     openDrawer
                                                                // }
                                                                handleSetting={
                                                                    handleSetting
                                                                }
                                                                name={item.key}
                                                                row={data}
                                                            />
                                                        )
                                                    }
                                                },
                                            )}
                                        </TableRow>
                                    )
                                },
                            )}
                        </TableBody>
                    </Table>
                    <TaskNoRecord record={auditors} />
                </TableContainer>
                <TablePagination
                    pagination={pagination}
                    setPagination={setPagination}
                />
                <AuditorEditSetting
                    open={openSetting}
                    auditorData={auditorData}
                    onClose={handleSetting}
                    updateAuditorList={updateAuditorList}
                />
            </div>
        </div>
    ) : (
        <></>
    )
}

type TableHeaderFieldProps = {
    name?: string
    row?: any
    encounterData?: any
    openDrawer?: any
    setFlagShowMsg?: any
    addendumRow?: any
    addendumIndex?: any
    border?: any
    borderbottom?: any
    borderLeft?: any
    handleSetting?: any
}
const BodyField = ({
    row,
    // encounterData,
    name,
    openDrawer,
    setFlagShowMsg,
    addendumRow,
    addendumIndex,
    border,
    borderbottom,
    borderLeft,
    handleSetting,
}: TableHeaderFieldProps) => {
    const [encounterData, setEncounterData] = useState<any>()
    const [encounterpause, setEncounterPause] = useState<boolean>(false)
    const navigate = useNavigate()
    // console.log('row', row)
    // console.log('managername', name)

    if (row) {
        switch (name) {
            case 'userName':
            case 'firstName':
            case 'lastName':
            case 'providerName':
            case 'mobile':
            case 'secSpecialty':
            case 'primSpecialty':
            case 'assignedProvider':
                return (
                    <TableCell
                        size="small"
                        // align={
                        //     name ===
                        //         'taskName' ||
                        //     name ===
                        //         'orgTid' ||
                        //     name === 'mrn'
                        //         ? 'left'
                        //         : 'center'
                        // }
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                        }}
                    >
                        {row[name]}
                    </TableCell>
                )
            case 'email':
                return (
                    <TableCell
                        size="small"
                        width="20%"
                        // align={
                        //     name ===
                        //         'taskName' ||
                        //     name ===
                        //         'orgTid' ||
                        //     name === 'mrn'
                        //         ? 'left'
                        //         : 'center'
                        // }
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            maxWidth: 20,
                        }}
                    >
                        {row[name]}
                    </TableCell>
                )
            case 'dateCreated':
            case 'workHours':
            case 'lastLogin':
                return (
                    <TableCell
                        size="small"
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                        }}
                    >
                        {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null}
                    </TableCell>
                )
            case 'galenTime':
            case 'auditorTAT':
            case 'totalTAT':
                return (
                    <TableCell
                        size="small"
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            whiteSpace: 'wrap',
                        }}
                    >
                        {/* {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null} */}
                    </TableCell>
                )
            // Add other cases as needed
            case 'edit':
                return (
                    <TableCell size="medium" style={{ padding: 0 }}>
                        <IconButton
                            onClick={() => handleSetting(true, row)}
                            // size="10"
                        >
                            <EditOutlinedIcon
                                style={{ width: '20px', height: '20px' }}
                            />
                        </IconButton>
                    </TableCell>
                )
            default:
                return (
                    <TableCell
                        size="small"
                        align="right"
                        key={row.id}
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                            borderRight: borderLeft,
                        }}
                    >
                        <Toolbar
                            style={{ padding: 0, minHeight: 0 }}
                            sx={{ padding: 0, margin: 0 }}
                        >
                            <Tooltip title="Open Message">
                                <div>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            openDrawer(5, row)
                                            setFlagShowMsg(1)
                                        }}
                                    >
                                        <img
                                            src={MessageIcon}
                                            alt="message-icon"
                                            style={{ width: 30, height: 30 }}
                                        />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <TableThreeDotMenu
                                data={row}
                                onclick={openDrawer}
                            />
                        </Toolbar>
                    </TableCell>
                )
        }
    } else {
        return <></>
    }
}

export default ManagerUserManagement
