import { Button, Tab, Tabs } from '@mui/material'
import React from 'react'
import ManagerJobReport from './ManagerJobReport'
import ManagerActivitySummary from './ManagerActivitySummary'
import ManagerOnHoldRerport from './ManagerOnHoldRerport'
import ManagerProviderReport from './ManagerProviderReport'
import styles from './ManagerReport.module.css'
interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    )
}

function ManagerReportHome() {
    const [value, setValue] = React.useState(1)
    const handleChange = (newValue: number) => {
        setValue(newValue)
    }

    const ReportSwitch = () => {
        switch (value) {
            case 1:
                return (
                    <>
                        <ManagerJobReport />
                    </>
                )
            case 2:
                return (
                    <>
                        <ManagerActivitySummary />
                    </>
                )
            case 3:
                return (
                    <>
                        <ManagerOnHoldRerport />
                    </>
                )
            case 4:
                return (
                    <>
                        <ManagerProviderReport />
                    </>
                )
        }
    }

    return (
        <div>
            <div className={styles.TopBar}>
                <Button
                    onClick={() => handleChange(1)}
                    style={{
                        border: '1px solid #fcc995',
                        borderRadius: '10px 10px 0px 0px',
                        background: value == 1 ? '#fcc995' : '#faf0e6',
                    }}
                >
                    Job Reports
                </Button>
                <Button
                    onClick={() => handleChange(2)}
                    style={{
                        border: '1px solid #fcc995',
                        borderRadius: '10px 10px 0px 0px',
                        background: value == 2 ? '#fcc995' : '#faf0e6',
                    }}
                >
                    User Activity Summary
                </Button>
                <Button
                    onClick={() => handleChange(3)}
                    style={{
                        border: '1px solid #fcc995',
                        borderRadius: '10px 10px 0px 0px',
                        background: value == 3 ? '#fcc995' : '#faf0e6',
                    }}
                >
                    On-Hold Job Report
                </Button>
                <Button
                    onClick={() => handleChange(4)}
                    style={{
                        border: '1px solid #fcc995',
                        borderRadius: '10px 10px 0px 0px',
                        background: value == 4 ? '#fcc995' : '#faf0e6',
                    }}
                >
                    Provider Report
                </Button>
            </div>
            <div>{ReportSwitch()}</div>
        </div>
    )
}

export default ManagerReportHome
