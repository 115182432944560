import {
    Menu,
    MenuItem,
    IconButton,
    Button,
    Tooltip,
    Pagination,
} from '@mui/material'
import React from 'react'
import styles from './TablePagination.module.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import typoStyles from '../../Styles/component/Typography.module.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
type TablePaginationProps = {
    pagination: {
        count: number
        currentPage: number
        rowsPerPage: number[]
        totalPages: number
        totalRecords: number
    }
    setPagination: (value: any) => void
}

const TablePagination = ({
    pagination,
    setPagination,
}: TablePaginationProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const btnRef = React.useRef<any>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event.currentTarget) {
            setAnchorEl(event.currentTarget)
        }
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const setpageValue = (value: number) => {
        if (value) {
            setPagination({ ...pagination, count: value, currentPage: 1 })
        }
        setAnchorEl(null)
    }
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPagination({
            ...pagination,
            currentPage: value,
        })
    }
    return (
        <div className={styles.paginationDiv}>
            <Pagination
                count={pagination.totalPages}
                showFirstButton
                showLastButton
                onChange={handleChange}
                color="primary"
            />
            {/* <div className={styles.TablePaginations}>
                
                <Tooltip title="Backward">
                    <div>
                        <IconButton
                            size="small"
                            disabled={pagination.currentPage === 1}
                            onClick={() => {
                                setPagination({
                                    ...pagination,
                                    currentPage: pagination.currentPage - 1,
                                })
                            }}
                        >
                            <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </div>
                </Tooltip>
                <span className={typoStyles.typoRobotoNormalprimary1214}>
                    {`${
                        pagination.currentPage === 1
                            ? pagination.currentPage
                            : pagination.currentPage * pagination.count -
                              pagination.count +
                              1
                    }-${
                        pagination.count * pagination.currentPage >=
                        pagination.totalRecords
                            ? pagination.totalRecords
                            : pagination.count * pagination.currentPage
                    }`}
                </span>
                <Tooltip title="Forward">
                    <div>
                        <IconButton
                            size="small"
                            disabled={
                                pagination.totalPages === pagination.currentPage
                            }
                            onClick={() => {
                                setPagination({
                                    ...pagination,
                                    currentPage: pagination.currentPage + 1,
                                })
                            }}
                        >
                            <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </div>
                </Tooltip>
                <Button
                    id="basic-button"
                    ref={btnRef}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ height: '100%' }}
                    className={styles.secondarybutton}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 10,
                            justifyContent: 'center',
                        }}
                    >
                        <span
                            style={{ marginLeft: '8px' }}
                            className={typoStyles.typoRobotoRegoularprimary714}
                        >
                            {`${pagination.count}/Page`}
                        </span>
                        {!open ? (
                            <ExpandMoreIcon sx={{ color: 'gray' }} />
                        ) : (
                            <ExpandLessIcon sx={{ color: 'gray' }} />
                        )}
                    </div>
                </Button>
                <Menu
                    id="basic-menu"
                    sx={{
                        '& .MuiPaper-root ': {
                            width: `${btnRef?.current?.clientWidth}px`,
                        },
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {pagination.rowsPerPage.map((item) => (
                        <MenuItem key={item} onClick={() => setpageValue(item)}>
                            {item}
                        </MenuItem>
                    ))}
                </Menu>
            </div> */}
        </div>
    )
}

export default TablePagination
