import React from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist-min'
import styles from './JobStatusGraph.module.css'

function JobStatusGraph() {
    const Plot = createPlotlyComponent(Plotly)
    const plotdata = [
        {
            x: [
                'Overdue',
                'Scheduled',
                'Ready for Audit',
                'Audit in Progress',
                'Sent to Interface',
                'Completed',
                'Addendum Ready',
                'Addendum In Progress',
                'Addendum Merged',
                'Validated Record',
                'Validated Coding',
                'Validated Record & Coding',
                'Audit Suspended',
                'Audit Escalated',
            ],
            y: [1, 30, 25, 4, 20, 26, 2, 0, 0, 0, 0, 2, 1, 1],
            ylabel: 'score',
            type: 'bar',

            marker: {
                color: [
                    '#E5ADB6',
                    '#D4D3D5',
                    '#BCE7FD',
                    '#E5CBFF',
                    '#E9F5CE',
                    '#E9F5CE',
                    '#9DB4FF',
                    '#C183FF',
                    '#C9E686',
                    '#FEEFCB',
                    '#FEEFCB',
                    '#FEEFCB',
                    '#FFB800',
                    '#FDF160',
                ],
            },
        },
    ]
    return (
        <div>
            <Plot
                className={styles.JobStatusGraphContainer}
                data={plotdata}
                layout={{
                    font: { size: 16 },
                    title: 'Number of Jobs by Status',
                    xaxis: {
                        // title: 'Status',
                        title: {
                            text: 'Status',
                            font: { weight: 20, size: 25 },
                            standoff: 40,
                        },
                        // font: { size: 1 },
                        ticklabelposition: 'inside',
                        ticklabeloverflow: 'allow',
                        tickangle: -90,
                    },
                    yaxis: {
                        title: {
                            text: 'Number',
                            font: { weight: 20, size: 20 },
                            standoff: 20,
                        },
                        range: [0, 50],
                    },
                }}
                config={{ responsive: true }}
            />
        </div>
    )
}

export default JobStatusGraph
