import React from 'react'
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider'
import { auditorState } from '../taskManagerService/common'
import { getManagers } from '../api/taskAPIService'
import { getSpeciality, getProviders } from '../api/userAPIService'
import {
    AuditorlistProps,
    IdProps,
    ProviderlistProps,
} from '../types/taskmanagertypes'
import { updateAuditor } from '../api/auditorAPIService'

interface auditorSettingProps {
    onClose?: any
    auditorData: any
    updateAuditorList?: any
}
export function useAuditorSetting({
    onClose,
    auditorData,
    updateAuditorList,
}: auditorSettingProps) {
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [states, setStates] = React.useState(auditorState)
    const [providerList, setProviderList] = React.useState<ProviderlistProps[]>(
        [],
    )
    const [managerList, setManagerList] = React.useState<AuditorlistProps[]>([])
    const [specialityList, setSpecialityList] = React.useState<
        ProviderlistProps[]
    >([])

    React.useEffect(() => {
        async function initCall() {
            await getManagers().then((auditorsRes: any) => {
                if (auditorsRes) {
                    setManagerList(auditorsRes.users)
                }
            })
            await getSpeciality().then(async (providersRes: any) => {
                if (providersRes?.data) {
                    setSpecialityList(providersRes.data)
                }
            })
        }
        initCall()
    }, [])

    React.useEffect(() => {
        async function getData() {
            if (auditorData) {
                await getProviders({
                    specialityId: auditorData?.mainSpecialityId,
                }).then((providersRes: any) => {
                    if (providersRes) {
                        setProviderList(providersRes.providerList)
                    }
                })
                setStates({
                    ...states,
                    auditorId: auditorData?.auditorId,
                    mainSpecialityId: auditorData?.mainSpecialityId,
                    secondarySpecialityId: auditorData?.secondarySpecialityId,
                    managerId: auditorData?.manager?.id,
                    providerId: auditorData?.provider[0]?.id,
                })
            }
        }
        getData()
    }, [auditorData])

    const handleMainSpeciality = async (value: string) => {
        const speciality = specialityList?.find(
            (item: IdProps) => item.id === value,
        )
        if (speciality?.id) {
            setStates({
                ...states,
                mainSpecialityId: speciality?.id,
                providerId: '',
            })
            await getProviders({ specialityId: speciality?.id }).then(
                (providersRes: any) => {
                    if (providersRes) {
                        setProviderList(providersRes.providerList)
                    }
                },
            )
        }
    }
    const handleSpeSpeciality = async (value: string) => {
        const speciality = specialityList?.find(
            (item: IdProps) => item.id === value,
        )
        if (speciality?.id) {
            setStates({ ...states, secondarySpecialityId: speciality?.id })
        }
    }
    const handleProvider = async (value: string) => {
        const provider = providerList?.find(
            (item: IdProps) => item.id === value,
        )
        if (provider?.id) {
            setStates({ ...states, providerId: provider?.id })
        }
    }
    const handleManager = async (value: string) => {
        const provider = managerList?.find((item: IdProps) => item.id === value)
        if (provider?.id) {
            setStates({ ...states, managerId: provider?.id })
        }
    }
    const submitSetting = async () => {
        loaderSetting(true)
        const bodyObj = {
            ...states,
        }
        // console.log(bodyObj,"bodyObj");

        await updateAuditor(bodyObj).then((res: any) => {
            if (res?.status) {
                // console.log(res,"updateAuditor");

                updateAuditorList(res?.result)
                snackbarSetting(true, res?.message, 'success')
                loaderSetting(false)
            } else {
                snackbarSetting(true, res?.message, 'error')
                loaderSetting(false)
            }
        })

        onClose(false)
    }
    return {
        states,
        specialityList,
        handleMainSpeciality,
        handleSpeSpeciality,
        submitSetting,
        handleProvider,
        handleManager,
        providerList,
        managerList,
    }
}
