import React from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist-min'
import styles from './TATMeter.module.css'

interface TATMeterProps {
    TATData?: any
}
function TATMeterPlot(props: TATMeterProps) {
    // console.log('props', props.TATData)
    const Plot = createPlotlyComponent(Plotly)
    const data = [
        {
            domain: { x: [0, 1], y: [0, 1] },
            value: props.TATData,
            title: { text: 'TAT-Meter' },
            type: 'indicator',
            mode: 'gauge+number+delta',
            delta: { reference: 20 },
            gauge: {
                axis: { range: [null, 90] },
                steps: [
                    { range: [0, 30], color: 'lightgray' },
                    { range: [30, 60], color: 'gray' },
                ],
                threshold: {
                    line: { color: 'red', width: 4 },
                    thickness: 0.75,
                    value: 30,
                },
            },
        },
    ]

    const layout = {
        // margin: { t: 60, b: 20 },
        margin: { t: 50, b: 10, l: 20, r: 10 },
        // width: 350,
        // height: 100,
    }
    // Plotly.newPlot('myDiv', data, layout);

    return (
        <Plot
            className={styles.TATMeterContainer}
            data={data}
            layout={layout}
            config={{ responsive: true }}
        />
    )
}

export default TATMeterPlot
