import API from './index'

export const getPhraseRecordingText = () =>
    API.handleApiRequest(() => API.get('taxonomyRecordings/suggestphrases'))
// export const getPhraseRecordingText = () =>
//     API.handleApiRequest(() => API.get('taxonomyRecordings/suggestphrases'))
export const postPhraseRecording = (data: any) =>
    API.handleApiRequest(() => API.post('taxonomyRecordings/upload', data))
export const postListPRTAT = (
    data: any,
    signal: any,
    startDate?: Date,
    endDate?: Date,
) =>
    API.handleApiRequest(() =>
        API.postSignal(
            'taxonomyRecordings/TATTimings',
            { startDate, endDate },
            signal,
        ),
    )
export const postList2ATAT = (
    data: any,
    signal: any,
    startDate: Date,
    endDate: Date,
) =>
    API.handleApiRequest(() =>
        API.postSignal('/encounter/TATReport', { startDate, endDate }, signal),
    )
