import {
    TableCell,
    TableRow,
    TableBody,
    Link,
    IconButton,
    Badge,
    Toolbar,
    Tooltip,
    Button,
    CircularProgress,
} from '@mui/material'
import styles from '../../../TaskManager/TaskManager.module.css'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import { useTableService } from '../../../../Services/taskManagerService/tableService'
import UserComponent from '../../../UserComponent/UserComponent'
import { StatusTag } from '../../JobManagerMUIStyles'
import TableThreeDotMenu from '../../../../Components/TableThreeDotMenu/TableThreeDotMenu'
import { ReactComponent as CommentIcon } from '../../../../Assests/Icons/CommentIcon.svg'
import MessageIcon from '../../../../Assests/Icons/MessageIcon.png'
import tablebodyStyles from './TableBodyField.module.css'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    getEncounterData,
    startTask,
} from '../../../../Services/api/auditorAPIService'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
dayjs.extend(relativeTime)

export interface Name {
    filteredTask: any
    modalOpen: any
    openDrawer: any
    openTranscriptModel: any
    setFlagShowMsg?: any
    openFillablePdfModel?: any
    openDocuemt3PdfModel?: any
    from?: any
}
export default function TableBodyField(props: Name) {
    const {
        filteredTask,
        modalOpen,
        openDrawer,
        openTranscriptModel,
        setFlagShowMsg,
        openFillablePdfModel,
        openDocuemt3PdfModel,
        from,
    } = props

    // const navigate = useNavigate()
    const { selectField } = useTableService({
        from: from,
        filteredTask: filteredTask,
    })
    const userRole = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.userRole,
    )
    // console.log('filtered', filteredTask)
    // console.log('select', selectField)

    return (
        <>
            {selectField.field1 && filteredTask && (
                <TableBody>
                    {filteredTask?.map((row: any, index: any) => {
                        return (
                            <>
                                {row?.addendumEncounters?.length != 0
                                    ? row.addendumEncounters?.map(
                                          (
                                              addendum: any,
                                              addendumIndex: number,
                                          ) => {
                                              //   console.log(
                                              //       'tbfaddendum',
                                              //       addendum,
                                              //   )
                                              if (
                                                  addendum.displayStatus !=
                                                  'Addendum_Merged'
                                              ) {
                                                  return (
                                                      <TableRow
                                                          style={{
                                                              background:
                                                                  addendum
                                                                      ? '#FDF3B9'
                                                                      : 'none',
                                                          }}
                                                          key={
                                                              'addendum' +
                                                              addendumIndex +
                                                              addendum._id
                                                          }
                                                          hover
                                                          //   sx={{
                                                          //       '&:first-of-type td, &:first-of-type th':
                                                          //           {
                                                          //               borderLeft:
                                                          //                   '1px, solid',
                                                          //               borderColor:
                                                          //                   //   '#A0A0A0',
                                                          //                   '#000000',
                                                          //           },
                                                          //   }}
                                                      >
                                                          {Object.values(
                                                              selectField,
                                                          )?.map(
                                                              (item: any) =>
                                                                  item &&
                                                                  item.roles?.some(
                                                                      (
                                                                          ro: any,
                                                                      ) =>
                                                                          ro ===
                                                                          userRole,
                                                                  ) && (
                                                                      <BodyField
                                                                          key={
                                                                              item.key +
                                                                              item.id
                                                                          }
                                                                          openDrawer={
                                                                              openDrawer
                                                                          }
                                                                          name={
                                                                              item.key
                                                                          }
                                                                          row={
                                                                              row
                                                                          }
                                                                          addendumRow={
                                                                              addendum
                                                                          }
                                                                          addendumIndex={
                                                                              addendumIndex
                                                                          }
                                                                          modalOpen={
                                                                              modalOpen
                                                                          }
                                                                          openTranscriptModel={
                                                                              openTranscriptModel
                                                                          }
                                                                          openFillablePdfModel={
                                                                              openFillablePdfModel
                                                                          }
                                                                          openDocuemt3PdfModel={
                                                                              openDocuemt3PdfModel
                                                                          }
                                                                          setFlagShowMsg={
                                                                              setFlagShowMsg
                                                                          }
                                                                          border={
                                                                              addendumIndex ==
                                                                              0
                                                                                  ? '2px solid black'
                                                                                  : '0.5px solid black'
                                                                          }
                                                                          borderLeft={
                                                                              addendumIndex >=
                                                                              0
                                                                                  ? '2px solid black'
                                                                                  : ''
                                                                          }
                                                                      />
                                                                  ),
                                                          )}
                                                          {/* body field for hamburger */}
                                                          <BodyField
                                                              name={''}
                                                              row={row}
                                                              addendumRow={
                                                                  addendum
                                                              }
                                                              modalOpen={
                                                                  modalOpen
                                                              }
                                                              openDrawer={
                                                                  openDrawer
                                                              }
                                                              openTranscriptModel={
                                                                  openTranscriptModel
                                                              }
                                                              openFillablePdfModel={
                                                                  openFillablePdfModel
                                                              }
                                                              openDocuemt3PdfModel={
                                                                  openDocuemt3PdfModel
                                                              }
                                                              setFlagShowMsg={
                                                                  setFlagShowMsg
                                                              }
                                                              addendumIndex={
                                                                  addendumIndex
                                                              }
                                                              border={
                                                                  addendumIndex ==
                                                                  0
                                                                      ? '2px solid black'
                                                                      : '0.5px solid black'
                                                              }
                                                              borderLeft={
                                                                  addendumIndex >=
                                                                  0
                                                                      ? '2px solid black'
                                                                      : ''
                                                              }
                                                          />
                                                      </TableRow>
                                                  )
                                              }
                                              //   else {
                                              //   }
                                          },
                                      )
                                    : null}
                                <TableRow
                                    style={{
                                        background:
                                            row?.addendumEncounters?.length != 0
                                                ? '#FDF3B9'
                                                : 'none',
                                    }}
                                    key={row.id + index}
                                    hover
                                >
                                    {Object.values(selectField)?.map(
                                        (item: any, index: any) =>
                                            item &&
                                            item.roles?.some(
                                                (ro: any) => ro === userRole,
                                            ) && (
                                                <BodyField
                                                    key={
                                                        index +
                                                        ':original' +
                                                        item.key
                                                    }
                                                    openDrawer={openDrawer}
                                                    name={item.key}
                                                    row={row}
                                                    modalOpen={modalOpen}
                                                    openTranscriptModel={
                                                        openTranscriptModel
                                                    }
                                                    openFillablePdfModel={
                                                        openFillablePdfModel
                                                    }
                                                    openDocuemt3PdfModel={
                                                        openDocuemt3PdfModel
                                                    }
                                                    setFlagShowMsg={
                                                        setFlagShowMsg
                                                    }
                                                    border={
                                                        row.displayStatus ==
                                                        'Addendum_Merged'
                                                            ? '2px solid black'
                                                            : ''
                                                    }
                                                    borderbottom={
                                                        row?.addendumEncounters
                                                            ?.length != 0
                                                            ? '2px solid black'
                                                            : ''
                                                    }
                                                    borderLeft={
                                                        row.addendumEncounters
                                                            ?.length != 0
                                                            ? '2px solid black'
                                                            : ''
                                                    }
                                                />
                                            ),
                                    )}

                                    <BodyField
                                        name={''}
                                        row={row}
                                        modalOpen={modalOpen}
                                        openDrawer={openDrawer}
                                        openTranscriptModel={
                                            openTranscriptModel
                                        }
                                        openFillablePdfModel={
                                            openFillablePdfModel
                                        }
                                        openDocuemt3PdfModel={
                                            openDocuemt3PdfModel
                                        }
                                        setFlagShowMsg={setFlagShowMsg}
                                        border={
                                            row.displayStatus ==
                                            'Addendum_Merged'
                                                ? '2px solid black'
                                                : ''
                                        }
                                        borderbottom={
                                            row.addendumEncounters?.length != 0
                                                ? '2px solid black'
                                                : ''
                                        }
                                        borderLeft={
                                            row.addendumEncounters?.length != 0
                                                ? '2px solid black'
                                                : ''
                                        }
                                    />
                                </TableRow>
                            </>
                        )
                    })}
                </TableBody>
            )}
        </>
    )
}
type TableHeaderFieldProps = {
    name?: string
    modalOpen?: any
    row?: any
    encounterData?: any
    openDrawer?: any
    openTranscriptModel?: any
    setFlagShowMsg?: any
    openFillablePdfModel?: any
    openDocuemt3PdfModel?: any
    addendumRow?: any
    addendumIndex?: any
    border?: any
    borderbottom?: any
    borderLeft?: any
}
const BodyField = ({
    row,
    // encounterData,
    name,
    openDrawer,
    openTranscriptModel,
    setFlagShowMsg,
    openFillablePdfModel,
    openDocuemt3PdfModel,
    modalOpen,
    addendumRow,
    addendumIndex,
    border,
    borderbottom,
    borderLeft,
}: TableHeaderFieldProps) => {
    const [encounterData, setEncounterData] = useState<any>()
    const [encounterpause, setEncounterPause] = useState<boolean>(false)
    const navigate = useNavigate()
    // console.log('row', row)
    // console.log('managername', name)

    const handleClick = () => {
        // console.log('rowstatus', row.displayStatus != 'Processing')
        if (
            row.displayStatus != 'Processing' ||
            row.displayStatus != 'Scheduled'
        ) {
            if (addendumRow) {
                openDrawer(0, row, addendumIndex)
            } else {
                openDrawer(0, row)
            }
        }
    }
    const handleClick2 = () => {
        setEncounterPause(true)
        if (addendumIndex != null) {
            gatherEncounterData(row.encounterId, addendumRow.id)
            callStartTask()
        } else {
            gatherEncounterData(row.encounterId)
            callStartTask()
        }
    }
    const gatherEncounterData = async (
        encounterId: any,
        addendumEncounterId?: any,
    ) => {
        try {
            const resp = await getEncounterData({ encounterId: encounterId })
            // setEncounterPause(true)
            if (addendumEncounterId) {
                const resp2 = await getEncounterData({
                    encounterId: addendumEncounterId,
                })
                setEncounterPause(false)

                navigate('/coding', {
                    state: {
                        patientData: addendumRow,
                        encounterData: resp2.encounterData,
                        addendumIndex: addendumIndex,
                        addendum: true,
                        original: row,
                        originalData: resp.encounterData,
                    },
                })
            } else {
                setEncounterPause(false)

                navigate('/coding', {
                    state: {
                        patientData: row,
                        encounterData: resp.encounterData,
                    },
                })
                setEncounterPause(false)
            }
        } catch (err) {
            console.log('encounter data error', err)
        }
    }
    const callStartTask = async (addendumIndex?: any) => {
        try {
            if (addendumIndex != null) {
                const start = await startTask({
                    encounterId: addendumRow.id,
                })
            } else {
                const start = await startTask({ encounterId: row.encounterId })
            }
        } catch (err) {
            console.log('calling start task failed', err)
        }
    }

    if (row) {
        switch (name) {
            case 'taskName':
            case 'orgTid':
            case 'mrn':
            case 'location':
            case 'speciality':
            case 'patientAccount':
            case 'encounterCollection':
            case 'appointmentType':
            case 'cityState':
            case 'siteOfService':
            case 'cqProviderName':
                return (
                    <TableCell
                        size="small"
                        align={
                            name === 'taskName' ||
                            name === 'orgTid' ||
                            name === 'mrn'
                                ? 'left'
                                : 'center'
                        }
                        sx={{
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                            whiteSpace: 'wrap',
                        }}
                        className={
                            typoStyles.typoRobotoRegoularprimary714pointer
                        }
                        onClick={handleClick}
                    >
                        {row[name]}
                    </TableCell>
                )
            case 'startDate':
                return addendumIndex ? (
                    <TableCell
                        size="small"
                        align="left"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                        }}
                        className={typoStyles.typoRobotoRegoularprimary714}
                    >
                        {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null}
                    </TableCell>
                ) : (
                    <TableCell
                        size="small"
                        align="left"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                        }}
                        className={typoStyles.typoRobotoRegoularprimary714}
                    >
                        {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null}
                    </TableCell>
                )
            case 'TATtime':
                return (
                    <TableCell
                        size="small"
                        align="left"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                        }}
                        className={typoStyles.typoRobotoRegoularprimary714}
                    >
                        {dayjs(row['startDate']).toNow(true)}
                        {/* {moment().format('DD/MMM/yyyy')} */}
                        {/* {row[name]
                            ? moment(row[name]).format('DD/MMM/yyyy')
                            : null} */}
                    </TableCell>
                )

            case 'audioReceived':
                return addendumRow ? (
                    <TableCell
                        size="small"
                        align="left"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                        }}
                        className={typoStyles.typoRobotoRegoularprimary714}
                    >
                        {row?.createdAt
                            ? moment(row?.createdAt).format('DD/MMM/yyyy')
                            : moment(row.createdAt).format('DD/MMM/yyyy')}
                    </TableCell>
                ) : (
                    <TableCell
                        size="small"
                        align="left"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                        }}
                        className={typoStyles.typoRobotoRegoularprimary714}
                    >
                        {row?.createdAt
                            ? moment(row?.createdAt).format('DD/MMM/yyyy')
                            : moment(row.createdAt).format('DD/MMM/yyyy')}
                    </TableCell>
                )
            case 'auditorName':
            case 'providerName':
            case 'patientName':
                return row[name] != -1 ? (
                    <TableCell
                        size="small"
                        align="left"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                        }}
                        onClick={handleClick}
                    >
                        <UserComponent
                            auditorName={row[name]}
                            auditorId={
                                name === 'auditorName'
                                    ? row.auditorId
                                    : name === 'providerName'
                                    ? row.providerId
                                    : name === 'patientName'
                                    ? row.appointmentId
                                    : ''
                            }
                        />
                    </TableCell>
                ) : (
                    <TableCell
                        size="small"
                        align="left"
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                        }}
                        onClick={handleClick}
                    >
                        {/* <UserComponent
                            auditorName={row[name]}
                            auditorId={
                                name === 'auditorName'
                                    ? row.auditorId
                                    : name === 'providerName'
                                    ? row.providerId
                                    : name === 'patientName'
                                    ? row.appointmentId
                                    : ''
                            }
                        /> */}
                    </TableCell>
                )
            case 'status':
                // console.log('addendum', row)
                return (
                    <TableCell
                        size="small"
                        align="center"
                        sx={{
                            // whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                            borderLeft: borderLeft,
                        }}
                        className={typoStyles.typoRobotoRegoularprimary714}
                        // onClick={handleClick}
                    >
                        {addendumRow ? (
                            <div
                                style={{
                                    display: 'inline-flex',
                                }}
                            >
                                {/* <>{console.log('hasaddendum', addendumRow)}</> */}
                                {addendumRow.displayStatus ==
                                    'Addendum_Ready_for_Audit' ||
                                addendumRow.displayStatus ==
                                    'Addendum_in_Progress' ||
                                addendumRow.displayStatus ==
                                    'Addendum_Merged' ||
                                addendumRow.displayStatus ==
                                    'Addendum_Completed' ? (
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            aria-label="addendumCodingButton"
                                            // disabled={
                                            //     encounterData?.editedProgressNote ==
                                            //     null
                                            // }
                                            style={{
                                                textTransform: 'capitalize',
                                                // width: '5vw',
                                            }}
                                            onClick={() => {
                                                // console.log('click row', row)
                                                handleClick2()
                                                // callStartTask(addendumIndex)
                                            }}
                                            // variant="contained"
                                        >
                                            <div
                                                style={{
                                                    display: 'inline-flex',
                                                    width: '5dvw',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <StatusTag
                                                    status={
                                                        addendumRow.displayStatus
                                                    }
                                                />
                                            </div>
                                        </Button>
                                    </div>
                                ) : (
                                    <div style={{ display: 'inline-flex' }}>
                                        <StatusTag
                                            status={addendumRow.displayStatus}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{ display: 'inline-flex' }}>
                                <>
                                    {/* {console.log(
                                        'hasnoaddendum',
                                        row.encounterAllData.displayStatus,
                                    )} */}
                                </>
                                {row.displayStatus == 'Ready_for_Audit' ||
                                row.displayStatus == 'Audit_in_Progress' ||
                                row.displayStatus == 'Validated_Record' ||
                                row.displayStatus == 'Validated_Coding' ||
                                row.displayStatus ==
                                    'Valicated_Record_Coding' ||
                                row.displayStatus == 'Audit_Escalated' ||
                                row.displayStatus == 'Sent_to_Interface' ||
                                row.displayStatus == 'Addendum_Merged' ||
                                row.displayStatus == 'Completed' ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            // width: '10dvw',
                                        }}
                                    >
                                        <Button
                                            aria-label="originalCodingButton"
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => {
                                                // setEncounterPause(true)
                                                handleClick2()
                                            }}
                                            // variant="contained"
                                        >
                                            <div
                                                style={{
                                                    display: 'inline-flex',
                                                }}
                                            >
                                                <StatusTag
                                                    status={row?.displayStatus}
                                                />
                                            </div>
                                        </Button>
                                        {/* <CircularProgress size="30px" /> */}
                                        {encounterpause ? (
                                            <CircularProgress size="20px" />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : (
                                    <div style={{ display: 'inline-flex' }}>
                                        <StatusTag
                                            status={row?.displayStatus}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </TableCell>
                )

            case 'comment':
                return (
                    <TableCell
                        align="center"
                        size="small"
                        sx={{
                            whiteSpace: 'nowrap',
                            borderTop: border,
                            borderBottom: borderbottom,
                            borderRight: borderLeft,
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                openDrawer(5, row)
                            }}
                        >
                            {row.comment > 0 ? (
                                <Badge
                                    badgeContent={row.comment}
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            background: '#E93323',
                                            color: '#FFFFFF',
                                            zIndex: 0,
                                        },
                                    }}
                                    className={tablebodyStyles.badgeStyles}
                                >
                                    <CommentIcon
                                        className={tablebodyStyles.commentIcon}
                                    />
                                </Badge>
                            ) : (
                                <CommentIcon
                                    className={tablebodyStyles.commentIcon}
                                />
                            )}
                        </IconButton>
                    </TableCell>
                )
            // Add other cases as needed
            default:
                return (
                    <TableCell
                        size="small"
                        align="right"
                        key={row.id}
                        sx={{
                            whiteSpace: 'nowrap',
                            border: '0.5px solid #A0A0A0',
                            borderTop: border,
                            borderBottom: borderbottom,
                            borderRight: borderLeft,
                        }}
                    >
                        <Toolbar
                            style={{ padding: 0, minHeight: 0 }}
                            sx={{ padding: 0, margin: 0 }}
                        >
                            <Tooltip title="Open Message">
                                <div>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            fontSize: 20,
                                        }}
                                        onClick={() => {
                                            openDrawer(5, row)
                                            setFlagShowMsg(1)
                                        }}
                                    >
                                        <img
                                            src={MessageIcon}
                                            alt="message-icon"
                                            style={{ width: 30, height: 30 }}
                                        />
                                    </IconButton>
                                </div>
                            </Tooltip>
                            {/* <TableThreeDotMenu
                                data={row}
                                onclick={openDrawer}
                            /> */}
                        </Toolbar>
                    </TableCell>
                )
        }
    } else {
        return <></>
    }
}
