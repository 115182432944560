import { PRODUCTION_DATE, PRODUCTION_END_DATE } from './actionType'

const productionDateSetting = (data: any) => {
    return {
        type: PRODUCTION_DATE,
        payload: data,
    }
}
const productionDateEndSetting = (data: any) => {
    return {
        type: PRODUCTION_END_DATE,
        payload: data,
    }
}
export { productionDateSetting, productionDateEndSetting }
