import _ from 'lodash'

export const auditorListReduce = (state: any, action: any) => {
    switch (action.type) {
        case 'add':
            return action.payload
        case 'remove':
            return []
        case 'update': {
            const productIndex = state.findIndex(
                (item: any) => item.auditorId === action?.payload?.auditorId,
            )
            if (productIndex < 0) {
                return state
            }
            const update = [...state]
            update.splice(productIndex, 1, action.payload)

            return _.unionBy(update)
        }
        case 'update2': {
            const productIndex = state.findIndex(
                (item: any) => item.auditorId === action?.payload.auditorId,
                // (item: any) =>
                //     item.auditorId === 'b06eed8a-ac06-427d-90bc-84ae9656358d',
                // (item: any) =>
                //     console.log(
                //         'check',
                //         item.auditorId,
                //         action?.payload?.auditorId,
                //         item.auditorId === action?.payload?.auditorId,
                //     ),
            )
            if (productIndex < 0) {
                return state
            }
            const update = [...state]

            update[productIndex].TotalRecordings =
                action.payload.TotalRecordings

            return _.unionBy(update)
        }
        default:
            return state
    }
}
